import React, { useCallback, useEffect, useRef, useState } from 'react';
import { gridSpacing } from '../../common/themes/constants';
import { Grid, Card, CardContent, CardHeader, Alert } from '@mui/material';
import { HookFormUtils, MyTypography } from '../common/components';
import { capitalize, debounce, isEmpty } from 'lodash';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetPatient } from '../referral/redux/getPatient';
import Swal from 'sweetalert2';
import Patient from '../referral/models/Patient';
import BackendErrorHandler from '../common/components/hook-form/BackendErrorHandler';
import { useSaveReferralAsDraft } from '../referral/redux/saveReferralAsDraft';
import Error from '../common/Error';
import PatientDemographicsForm from './PatientDemographicsForm';
import { useLazyQuery } from '@apollo/client';
import clientGraphql from '../../common/apollo-graphql';
import { GET_REFERRAL_BY_UUID, LOAD_PATIENT_BY_SSN } from '../referral/gql/Query';

String.prototype.splice = function(idx, rem, str) {
  return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};

export default function PatientDemographics({
  referralUuid,
  onSetReferralId,
  parsedData,
  prefix,
  onDisableNext = null,
  isSavedDraft = false,
  isSameOrg = false,
  patientSchema = {},
  referralSchema = {},
  requiredCustom = [],
  initialValues = {},
  isFax = false,
  lastRequiredIndex = -1,
  providers=[],
  showOnlyPatientDemographics = false
}) {
  const gqlParams = {
    client: clientGraphql,
    variables: {
      ssn: '',
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
  };
  const [loadPatientBySsn] = useLazyQuery(LOAD_PATIENT_BY_SSN, gqlParams);
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    reset,
    setError,
    clearErrors,
    formState,
    watch,
  } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(
      yup.object().shape({
        referral: yup.object().shape(referralSchema),
        patient: yup.object().shape(patientSchema),
      }),
    ),
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const isFetchingRef = useRef(false);
  const [details, setDetails] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [loadedSSN] = useState({});
  const {
    saveReferralAsDraft,
    saveReferralAsDraftPending,
    saveReferralAsDraftError,
    dismissSaveReferralAsDraftError,
  } = useSaveReferralAsDraft();

  const handleRetrieve = useCallback(
    res => {
      console.log('Address Retrieved:', res);
      if (!res || !res.features || res.features.length === 0) {
        console.log('No address features found:', res);
        return;
      }

      const feature = res.features[0];
      setValue('patient.address_line1', feature.properties.address_line1);
      setValue('patient.address_city', feature.properties.place);
      setValue('patient.address_state', feature.properties.region);
      setValue('patient.address_zip', feature.properties.postcode);
      trigger('patient.address_line1');
      trigger('patient.address_city');
      trigger('patient.address_state');
      trigger('patient.address_zip');
    },
    [setValue, trigger],
  );

  
  const [referralUpdatedMessage, setReferralUpdatedMessage] = useState('');
  const debouncedSave = useCallback(
    debounce(async (referralUUID, args) => {
      setIsSaving(true);
      dismissSaveReferralAsDraftError();
      await saveReferralAsDraft(referralUUID, args)
        .then(data => {
          const { referral_uuid } = data;
          setIsSaving(false);
          if (isEmpty(referralUUID)) {
            onSetReferralId(referral_uuid);
          }
          reset({}, { keepValues: true, keepIsValid: false });
          setReferralUpdatedMessage(data.message);
        })
        .catch(err => {
          setIsSaving(false);
          onDisableNext(true);
          reset({}, { keepValues: true, keepIsValid: false });
        });
    }, 1500),
    [],
  );

  const hasAddressDirtyField = useCallback(() => {
    if ('patient' in formState.dirtyFields && 'address_line1' in formState.dirtyFields.patient) {
      return true;
    }
    if ('patient' in formState.dirtyFields && 'address_city' in formState.dirtyFields.patient) {
      return true;
    }
    if ('patient' in formState.dirtyFields && 'address_state' in formState.dirtyFields.patient) {
      return true;
    }
    if ('patient' in formState.dirtyFields && 'address_zip' in formState.dirtyFields.patient) {
      return true;
    }
    return false;
  }, [formState]);

  const params = {
    variables: {
      uuid: referralUuid,
    },
    client: clientGraphql,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
  };

  const [getReferralByUuid] = useLazyQuery(GET_REFERRAL_BY_UUID, params);

  const { getPatient, dismissGetPatientError } = useGetPatient();
  
  const onGetPatient = useCallback(() => {
    dismissGetPatientError();
    return getPatient(referralUuid)
      .then(data => {
        setDetails({
          ...data.data
        });
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: err.data,
          showConfirmButton: true,
        });
      });
  }, [getPatient, dismissGetPatientError, referralUuid]);  

  const updatedValues = useCallback(() => {
    if (!isEmpty(referralUuid) && !formState.isValidating && !hasAddressDirtyField()) {
      return HookFormUtils.dirtyValues(formState.dirtyFields, getValues());
    } else if (!isEmpty(referralUuid) && !formState.isValidating && hasAddressDirtyField()) {
      return {
        patient: {
          address_line1: getValues('patient.address_line1'),
          address_city: getValues('patient.address_city'),
          address_state: getValues('patient.address_state'),
          address_zip: getValues('patient.address_zip'),
        },
      };
    } else if (isEmpty(referralUuid) && !formState.isValidating) {
      return getValues();
    }
  }, [formState, getValues, hasAddressDirtyField, referralUuid]);

  const setReferralData = useCallback((referral) => {
    setValue('referral.diagnosis', referral.diagnosis?.description || '');
    setValue('referral.comment', referral.comment || '');
    setValue('referral.is_fax', referral.is_fax || false);
    setValue('referral.referrer_provider_id', referral.referrer_provider?.id || '');
    if(!isEmpty(referral) && !isEmpty(referral.extra_info)){
      var extra_info = JSON.parse(referral.extra_info);
      if (!isEmpty(extra_info)) {
        for (const [key, value] of Object.entries(extra_info)) {
          setValue('referral.' + key, value);
        }
      }
    }

    trigger('referral');
  }, [setValue, trigger]);

  const setPatientData = useCallback((patient) => {
    setValue('patient.ssn', patient.ssn);
    setValue('patient.mrn', patient.mrn || '');
    setValue('patient.firstname', patient.firstname);
    setValue('patient.lastname', patient.lastname);
    setValue('patient.birthdate', patient.birthdate);
    setValue('patient.phone', patient.phone);
    setValue('patient.insurance_provider', patient.insurance_provider);
    setValue('patient.policy_number', patient.policy_number);

    if (patient.address != null) {
      setValue('patient.address_line1', patient.address.line1);
      setValue('patient.address_city', patient.address.city);
      setValue('patient.address_state', patient.address.state);
      setValue('patient.address_zip', patient.address.zip);
    }

    trigger('patient');
  }, [setValue, trigger]);

  useEffect(() => {
    if (!isEmpty(referralUuid) && !isEmpty(details)) {
      setPatientData(details.patient);
      setReferralData(details.referral);
    }
  }, [setPatientData, setReferralData, details, referralUuid]);

  useEffect(() => {
    if (!isEmpty(providers) && providers.length === 1 && isEmpty(getValues('referral.referrer_provider_id'))) {
      setValue('referral.referrer_provider_id', providers[0].id);
      trigger('referral.referrer_provider_id');
    }
  }, [setValue, trigger, getValues, providers]);

  useEffect(() => {
    if (!isEmpty(parsedData)) {
      setValue('referral.clinic_receiver_id', parsedData.clinic.id);
      setValue('referral.provider_id', parsedData.clinic.provider_id);
      setValue('referral.is_fax', parsedData.clinic.isFax || false);
      trigger('referral.clinic_receiver_id');
      trigger('referral.provider_id');
      trigger('referral.is_fax');
    }
  }, [trigger, setValue, parsedData, reset, getValues]);

  useEffect(() => {
    if (
      referralUuid &&
      isEmpty(getValues('patient.ssn')) &&
      isEmpty(details) &&
      !isFetchingRef.current
    ) {
      isFetchingRef.current = true;
      onGetPatient().finally(() => {
        isFetchingRef.current = false;
      });
    }
  }, [referralUuid, details, getValues, onGetPatient]);
  

  useEffect(() => {
    if (formState.isValid && !formState.isValidating && !isEmpty(formState.dirtyFields)) {
      const val = JSON.parse(JSON.stringify(updatedValues()));
      if (val.hasOwnProperty('patient') && val['patient'].hasOwnProperty('ssn')) {
        val.patient.ssn = val.patient.ssn.replace(/-/g, '');
      }
      if (val.hasOwnProperty('patient') && val.patient.hasOwnProperty('phone')) {
        val.patient.phone = val.patient.phone.replace(/-/g, '');
      }
      if (!isSaving) {
        if (!isEmpty(val) && !isEmpty(val.referral)) {
          val.referral.is_fax = parsedData.clinic.isFax;
        }
        handleSubmit(debouncedSave(referralUuid, val));
      }
    }

    onDisableNext(!formState.isValid);
  }, [
    parsedData,
    formState,
    referralUuid,
    handleSubmit,
    debouncedSave,
    updatedValues,
    getValues,
    setValue,
    trigger,
    onDisableNext,
    isSaving,
    isFax,
  ]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        name === 'patient.ssn' &&
        value.patient.ssn.length === 11 &&
        isEmpty(referralUuid)
      ) {
        loadPatientBySsn({
          client: clientGraphql,
          variables: { ssn: value.patient.ssn.replaceAll('-', '') },
        }).then(result => {
          if (
            result.hasOwnProperty('data') &&
            !isEmpty(result.data) &&
            result.data.hasOwnProperty('patient_by_ssn')
          ) {
            const { patient_by_ssn: patient } = result.data;
            if (!isEmpty(patient)) {
              setPatientData(patient);
            }
          }
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, referralUuid, loadPatientBySsn, loadedSSN, setPatientData, setValue]);

  return (
    <Card>
      {!showOnlyPatientDemographics ? 
        <CardHeader
          title={parsedData.clinic.provider_name + ' - ' + capitalize(parsedData.specialty.name)}
          subheader={parsedData.clinic.name + ' - ' + parsedData.clinic.address}
        /> : null
      }
      <CardContent>
        <Grid container spacing={gridSpacing}>
          {!showOnlyPatientDemographics ? 
            <Grid item sm={12} xs={12}>
              <MyTypography>
                STATUS:{' '}
                {!isEmpty(referralUuid)
                  ? 'Saved as Draft'
                  : saveReferralAsDraftPending
                  ? 'Submitting...'
                  : 'Unsaved'}
              </MyTypography>
            </Grid> : null
          }
          <Grid item sm={12} xs={12}>
            {saveReferralAsDraftError && (
              <Grid item md={12} xs={12} sx={{ pb: 2 }}>
                <BackendErrorHandler
                  error={saveReferralAsDraftError}
                  setError={setError}
                  clearErrors={clearErrors}
                />
                <Error error={saveReferralAsDraftError} />
              </Grid>
            )}

            {showOnlyPatientDemographics && referralUpdatedMessage && (
              <Grid item md={12} xs={12} sx={{ pb: 2 }}>
                <Alert severity="info" sx={{ width: '100%' }}>
                  {referralUpdatedMessage}
                </Alert>
              </Grid>
            )}

            <PatientDemographicsForm
              control={control}
              prefix={prefix}
              errors={formState.errors}
              handleRetrieve={handleRetrieve}
              requiredCustom={requiredCustom}
              isSameOrg={isSameOrg}
              lastRequiredIndex={lastRequiredIndex}
              providers={providers}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
