import React from 'react';
import {
    MenuItem,
} from '@mui/material';
import HookFormSelectOnly from '../../common/components/hook-form/HookFormSelectOnly';
var options = [{label: 'All', value: 'all'}, {label: 'Faxed', value: 'fax'}, {label: 'Zipreferred', value: 'none'}];

export default function ReferralTypeOptions({prefix=null, control=null, errors=null, onChange=null}) {


    return (
        <HookFormSelectOnly
            id="view"
            type="select"
            select
            placeholder="Select View"
            label="View"
            defaultValue='all'
            schema="view"
            prefix={prefix}
            control={control}
            errors={errors}
            fullWidth
            onChange={onChange}
            options={options}
        />
    );
}