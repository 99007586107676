import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export default function HookFormSelectOnly({
  onChange = null,
  options,
  ...others
}) {
  const {label, defaultValue} = others;

  return (
    <FormControl style={{width: 200}}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={label}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {options.map(option => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
