import { gql } from '@apollo/client';
import { GET_PROVIDER_FIELDS } from '../../referral/gql/Fragment';


const SAVE_PUBLIC_REFERRAL_MUTATION = gql `
  mutation SavePublicReferral($referral: String!, $patient: PublicPatientAttributes!) {
    save_public_referral(referral: $referral, patient:$patient)
  }
`;

const SAVE_PUBLIC_REFERRAL_QUESTIONS_MUTATION = gql `
  mutation SaveReferralAnswers($uuid: String!, $answers: [ReferralAnswerAttributes!]) {
    save_referral_answers(referral_uuid: $uuid, answers: $answers)
  }
`;

const VERIFY_PUBLIC_REFERRAL = gql`
${GET_PROVIDER_FIELDS}
mutation Verify($referralUuid: String!, $email: String!, $code: String!) {
  referral: verify_referral_access_code(referral_uuid: $referralUuid, email: $email, access_code: $code) {
    id
    status
    comment
    mrn
    patient {
      firstname
      lastname
      full_name
      details {
        phone
        ssn
      }
    }
    referrer {
      name
      metadata {
        clinic_receiver_id
        clinic_name
        physician
        contact
        fax
        office_phone
        contact_email
        provider_uuid
        address
      }
    }
    receiver {
      id
      name
      full_address
      extra_info
      organization {
        name
      }
    }
    diagnosis {
      description
    }
    provider {
      ...ProviderFields
    }
    reason
    appointment {
      scheduled_by
      appointment_date
    }
    documents {
      id
      uuid
      referral_document_name
      name
      url
      questions {
        question
      }
      
    }
  }
}
`;

const VERIFY_PUBLIC_REFERRAL_FULL = gql`
mutation Verify($referralUuid: String!, $email: String!, $code: String!) {
  referral: verify_referral_access_code(referral_uuid: $referralUuid, email: $email, access_code: $code) {
    id
    status
    patient {
      full_name
      details {
        phone
        ssn
        birthdate
        full_address
        insurance_provider
        policy_number
      }
    }
  }
}
`;


const SEND_NOTE = gql`
mutation SendNote($referralUuid: String!, $email: String!, $code: String!, $note: String!) {
  send_note(referral_uuid: $referralUuid, email: $email, access_code: $code, note: $note)
}
`;


export {
    SAVE_PUBLIC_REFERRAL_MUTATION,
    SAVE_PUBLIC_REFERRAL_QUESTIONS_MUTATION,
    VERIFY_PUBLIC_REFERRAL,
    VERIFY_PUBLIC_REFERRAL_FULL,
    SEND_NOTE
  };
  