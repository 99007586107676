import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Checkbox,
  TextField,
} from '@mui/material';
import MyPaperComponent from '../../common/components/MyPaperComponent';
import { Controller, useForm } from 'react-hook-form';
import { useSetAppointmentReferral } from '../../referral/redux/setAppointmentReferral';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const ReferralCompleteDialog = ({ initialOpen, referralUuid, initialAppointmentDate, onPromoteReferral, onClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [appointmentMaintained, setAppointmentMaintained] = useState(false);
  const [confirmAppointmentDate, setConfirmAppointmentDate] = useState(false);
  const [confirmResults, setConfirmResults] = useState(false);
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [rowId, setRowId] = useState(referralUuid);
  const { setAppointmentReferral, _ } = useSetAppointmentReferral();

  const { control, getValues, setValue } = useForm();

  const formatDate = (date) => {
    return date ? dayjs(date, 'DD MMM YYYY - HH:mm:ss') : dayjs();
  };

  useEffect(() => {
    if (initialOpen) {
      setCurrentStep(1);
      setAppointmentMaintained(false);
      setConfirmAppointmentDate(false);
      setValue('appointment_date', formatDate(initialAppointmentDate));
      setConfirmResults(false);
      setReason('');
      setRowId(referralUuid);
    }
  }, [initialOpen, initialAppointmentDate, referralUuid, setValue]);

  const handleNext = useCallback((nextStep) => {
    if (currentStep === 1) {
      setCurrentStep(nextStep);
    } else if (currentStep === 2) {
      if (confirmAppointmentDate && confirmResults) {
        handleConfirm();
      }
    } else if (currentStep === 3 && reason && (reason != 'Other' || otherReason)) {
      handleConfirm();
    }
  }, [currentStep, handleConfirm, reason, otherReason, confirmAppointmentDate, confirmResults]);

  const handleConfirm = useCallback(() => {
    var reasonVal = reason == 'Other' ? otherReason : reason;

    const data = {
      appointmentMaintained,
      appointmentDate: dayjs(getValues('appointment_date')).second(0).format('YYYY-MM-DD HH:mm:ss'),
      confirmAppointmentDate,
      confirmResults,
      reason: reasonVal
    };

    onUpdateReferralAppointmentDate();
    onPromoteReferral(rowId, 'ref_completed', data);
    onClose();
  }, [
    onPromoteReferral, 
    onUpdateReferralAppointmentDate, 
    onClose, 
    getValues, 
    appointmentMaintained, 
    confirmAppointmentDate, 
    confirmResults, 
    reason, 
    otherReason, 
    rowId
  ]);

  const onUpdateReferralAppointmentDate = useCallback(() => {
    let oldDate = dayjs(initialAppointmentDate).second(0).format('YYYY-MM-DD HH:mm:ss');
    let newDate = dayjs(getValues('appointment_date')).second(0).format('YYYY-MM-DD HH:mm:ss');
    if (oldDate == newDate) return;
    if (!appointmentMaintained) return;

    const formData = new FormData();
    formData.append('appointment_date', newDate);

    console.log("updating appointment date", rowId, newDate);
    setAppointmentReferral(rowId, formData);
  }, [initialAppointmentDate, appointmentMaintained, setAppointmentReferral, getValues, rowId]);

  return (
    <>
      <Dialog open={initialOpen} onClose={onClose} PaperComponent={MyPaperComponent} classes={{ paper: 'width-400' }}>
        {currentStep === 1 && (
          <>
            <DialogContent style={{ padding: '20px' }}>
              <Typography>Did the patient maintain the appointment?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                setAppointmentMaintained(true);
                handleNext(2);
              }}>Yes</Button>
              <Button onClick={() => {
                setAppointmentMaintained(false);
                handleNext(3);
              }}>No</Button>
            </DialogActions>
          </>
        )}

        {currentStep === 2 && (
          <>
            <DialogContent style={{ padding: '20px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
                <Checkbox
                  style={{ margin: '-10px', marginRight: '-15px' }}
                  checked={confirmAppointmentDate}
                  onChange={(e) => setConfirmAppointmentDate(e.target.checked)}
                />
                Confirm Appointment Date:
                <Controller
                  name="appointment_date"
                  control={control}
                  defaultValue={formatDate(initialAppointmentDate)}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        {...field}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        value={field.value ? dayjs(field.value) : null}
                        label="Appointment Date and Time *"
                        ampm={false}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <Checkbox
                  style={{ margin: '-10px', marginRight: '-15px' }}
                  checked={confirmResults}
                  onChange={(e) => setConfirmResults(e.target.checked)}
                />
                Scanned follow-up documents into patient medical record
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleNext} disabled={!confirmAppointmentDate || !confirmResults}>
                Confirm
              </Button>
            </DialogActions>
          </>
        )}

        {currentStep === 3 && (
          <>
            <DialogContent style={{ padding: '20px' }}>
              <Typography style={{ paddingBottom: 20 }}>Reasoning?</Typography>
              <FormControl fullWidth>
                <InputLabel id="reason-label" >Select a reason</InputLabel>
                <Select
                  labelId="reason-label"
                  id="reason"
                  value={reason}
                  label="Select a reason"
                  onChange={(e) => setReason(e.target.value)}
                >
                  <MenuItem value="Patient Declined">Patient Declined</MenuItem>
                  <MenuItem value="No Show">No Show</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>

              {reason === "Other" && (
                <TextField
                  fullWidth
                  margin="normal"
                  label="Please specify"
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleNext} disabled={!(reason && (reason != "Other" || otherReason))}>
                Confirm
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ReferralCompleteDialog;
