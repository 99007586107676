import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardContent,
  DialogActions,
} from '@mui/material';
import React from 'react';
import { isEmpty } from 'lodash';
import MyPaperComponent from '../../common/components/MyPaperComponent';
import { MyButton } from '../../common/components';
import ReferralNotes from '../../referral/ReferralNotes';

export default function DialogReferralNotes({ referralUuid, openNotes, handleClose, isExternal = false }) {
  return (
    <Dialog
      open={openNotes}
      onClose={handleClose}
      PaperComponent={MyPaperComponent}
      aria-labelledby="draggable-dialog-title"
      style={{ minWidth: '800px' }}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Referral Log
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Card style={{ padding: '0' }}>
            <CardContent>
              {!isEmpty(referralUuid) && <ReferralNotes uuid={referralUuid} isExternal={isExternal} />}
            </CardContent>
          </Card>
        </Box>
      </DialogContent>
      <DialogActions>
        <MyButton autoFocus onClick={handleClose} fullWidth={false}>
          Close
        </MyButton>
      </DialogActions>
    </Dialog>
  );
}
