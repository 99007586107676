import React from 'react';
import { Controller } from 'react-hook-form';
import HookFormUtils from './HookFormUtils';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Stack, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function HookFormDateTimePicker({
  control,
  schema,
  variant,
  type = 'text',
  loading,
  errors,
  label,
  prefix = null,
  ...others
}) {
  const error = HookFormUtils.getFieldError(errors, schema, prefix);
  const _schema = HookFormUtils.getSchemaName(schema, prefix);

  return (
    <Controller
      control={control}
      name={_schema}
      render={({ field: { onChange, value } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DateTimePicker
              name={schema}
              onChange={onChange}
              value={value}
              variant={variant || 'outlined'}
              label={label}
              ampm={false}
              getViewSwitchingButtonText={day => day.toLowerCase()}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  variant={variant}
                  error={error ? true : false}
                  helperText={error && error.message}
                />
              )}
              {...others}
            />
          </Stack>
        </LocalizationProvider>
      )}
    />
  );
}