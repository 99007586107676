import React, { useCallback, useEffect } from 'react';
import { gridSpacing } from '../../common/themes/constants';
import { Grid } from '@mui/material';
import { HookFormTextField, MyTypography, HookFormTextAreaAutoSize } from '../common/components';
import * as yup from 'yup';
import MobileNumberMask from '../common/components/mask/MobileNumberMask';
import HookFormDatePicker from '../common/components/hook-form/HookFormDatePicker';
import SSNMask from '../common/components/mask/SSNMask';
import moment from 'moment';
import AddressZipMask from '../common/components/mask/AddressZipMask';
import { AddressAutofill, config } from '@mapbox/search-js-react';
import { isEmpty } from 'lodash';
import HookFormRadio from '../common/components/hook-form/HookFormRadio';
import HookFormCheckboxGroup from '../common/components/hook-form/HookFormCheckboxGroup';
import { MAPBOX_ACCESS_TOKEN, geocoderApiOptions } from '../common/services/DefaultValuesUtils';
import ProviderOptions from '../widget/ProviderOptions';
import ReferralProviderOptions from '../widget/ReferralProviderOptions';

const today = moment().subtract(0, 'days');

export const initialValues = {
  referral: {
    clinic_receiver_id: -1,
    provider_id: -1,
    diagnosis: '',
    comment: '',
    referrer_provider_id: '',
  },
  patient: {
    firstname: '',
    lastname: '',
    phone: '',
    ssn: '',
    mrn: '',
    birthdate: '',
    address_line1: '',
    address_city: '',
    address_state: '',
    address_zip: '',
    insurance_provider: '',
    policy_number: '',
  },
};

export const createReferralValidationSchema = {
  diagnosis: yup.string().required('Diagnosis is required'),
  comment: yup.string().required('Comment is required'),
};

export const createPatientValidationSchema = {
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  ssn: yup
    .string()
    .test('length', 'SSN must have 9 digits', value => {
      const digits = value.replace(/\D/g, ''); // Remove non-digit characters
      return digits.length === 9;
    })
    .required('SSN is required')
    .typeError('SSN is required'),
  mrn: yup.string().required('MRN is required'),
  birthdate: yup
    .date('Birthdate is invalid.')
    .max(today, 'Birthdate must be today or a past date.')
    .required('Birthdate is required.')
    .typeError('Birthdate is invalid.'),
  phone: yup
    .string()
    .required('Phone number is required')
    .test('length', 'Phone number must have 10 digits', value => {
      const digits = value.replace(/\D/g, ''); // Remove non-digit characters
      return digits.length === 10;
    }),
  address_line1: yup.string().required('Address 1 is required'),
  address_city: yup.string().required('City is required'),
  address_state: yup.string().required('State is required'),
  address_zip: yup
    .string()
    .length(5, 'Zip code must have 5 digits')
    .required('Zip code is required'),
  insurance_provider: yup.string().required('Insurance Provider is required'),
  policy_number: yup.string().required('Policy Number is required'),
};

export default function PatientDemographicsForm({
  control,
  errors,
  prefix = null,
  handleRetrieve,
  isSameOrg = false,
  requiredCustom = [],
  lastRequiredIndex = -1,
  providers = [],
}) {
  useEffect(() => {
    config.accessToken = MAPBOX_ACCESS_TOKEN;
  }, []);

  const getPrefix = useCallback((fieldName, prefix) => {
    return fieldName.includes('custom_') ||
      fieldName.includes('diagnosis') ||
      fieldName.includes('comment') ||
      fieldName.includes('referrer_provider_id')
      ? 'referral'
      : prefix;
  }, []);

  const getPlaceholder = useCallback((fieldName, label) => {
    if (fieldName === 'ssn') {
      return '###-##-####';
    } else {
      return 'Enter ' + label;
    }
  }, []);

  const getLabel = useCallback(
    custom => {
      return (
        custom.label +
        (custom.required ||
        (isSameOrg &&
          (custom.field === 'firstname' || custom.field === 'lastname' || custom.field === 'ssn'))
          ? ' *'
          : '')
      );
    },
    [isSameOrg],
  );

  const getWidth = useCallback(custom => {
    return !isEmpty(custom.size) ? custom.size : 6;
  }, []);

  const getInputProps = useCallback(fieldName => {
    if (fieldName === 'ssn') {
      return {
        inputComponent: SSNMask,
      };
    } else if (fieldName === 'phone') {
      return { inputComponent: MobileNumberMask };
    } else if (fieldName === 'address_zip') {
      return {
        inputComponent: AddressZipMask,
      };
    } else {
      return null;
    }
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item sm={12} xs={12}>
        <h3 style={{ margin: '0' }}>Patient Demographics</h3>
      </Grid>
      {!isEmpty(providers) && (
        <Grid item sm={12} xs={12}>
          <ReferralProviderOptions
            prefix={'referral'}
            control={control}
            errors={errors}
            providers={providers}
            schema={'referrer_provider_id'}
          />
        </Grid>
      )}

      {!isEmpty(requiredCustom) &&
        requiredCustom.map((custom, index) => {
          return (
            <>
              <Grid item sm={getWidth(custom)} xs={12}>
                {custom.kind === 'single_choice' && (
                  <HookFormRadio
                    id={custom.field}
                    name={custom.field}
                    choices={!isEmpty(custom.choices) ? custom.choices : []}
                    placeholder={getPlaceholder(custom.field, custom.label)}
                    label={getLabel(custom)}
                    schema={custom.field}
                    prefix={getPrefix(custom.field, prefix)}
                    control={control}
                    errors={errors}
                    fullWidth
                  />
                )}
                {custom.kind === 'multiple_choice' && (
                  <HookFormCheckboxGroup
                    id={custom.field}
                    name={custom.field}
                    choices={!isEmpty(custom.choices) ? custom.choices : []}
                    placeholder={getPlaceholder(custom.field, custom.label)}
                    label={getLabel(custom)}
                    schema={custom.field}
                    prefix={getPrefix(custom.field, prefix)}
                    control={control}
                    errors={errors}
                    fullWidth
                  />
                )}
                {isEmpty(custom.kind) ||
                  (custom.kind === 'text' && (
                    <HookFormTextField
                      id={custom.field}
                      name={custom.field}
                      placeholder={getPlaceholder(custom.field, custom.label)}
                      label={getLabel(custom)}
                      schema={custom.field}
                      prefix={getPrefix(custom.field, prefix)}
                      control={control}
                      errors={errors}
                      InputProps={getInputProps(custom.field)}
                      fullWidth
                      autoFocus={custom.field === 'ssn'}
                    />
                  ))}
                {custom.kind === 'phone' && (
                  <HookFormTextField
                    id={custom.field}
                    name={custom.field}
                    placeholder={getPlaceholder(custom.field, custom.label)}
                    label={getLabel(custom)}
                    schema={custom.field}
                    prefix={getPrefix(custom.field, prefix)}
                    control={control}
                    errors={errors}
                    InputProps={getInputProps(custom.field)}
                    fullWidth
                    autoFocus={custom.field === 'ssn'}
                  />
                )}
                {custom.kind === 'date' && (
                  <HookFormDatePicker
                    id={custom.field}
                    name={custom.field}
                    placeholder={getPlaceholder(custom.field, custom.label)}
                    label={getLabel(custom)}
                    schema={custom.field}
                    disableFuture
                    prefix={getPrefix(custom.field, prefix)}
                    control={control}
                    errors={errors}
                    fullWidth
                  />
                )}

                {custom.kind === 'address' && (
                  <AddressAutofill
                    accessToken={MAPBOX_ACCESS_TOKEN}
                    options={geocoderApiOptions}
                    onRetrieve={handleRetrieve}
                  >
                    <HookFormTextField
                      id={custom.field}
                      name={custom.field}
                      placeholder={getPlaceholder(custom.field, custom.label)}
                      label={getLabel(custom)}
                      autoComplete={custom.field}
                      schema={custom.field}
                      className="input mb12"
                      prefix={getPrefix(custom.field, prefix)}
                      InputProps={getInputProps(custom.field)}
                      control={control}
                      errors={errors}
                      fullWidth
                    />
                  </AddressAutofill>
                )}

                {custom.kind === 'textarea' && (
                  <HookFormTextAreaAutoSize
                    id={custom.field}
                    name={custom.field}
                    placeholder={getPlaceholder(custom.field, custom.label)}
                    label={getLabel(custom)}
                    schema={custom.field}
                    prefix={getPrefix(custom.field, prefix)}
                    control={control}
                    errors={errors}
                    fullWidth
                    style={{ width: '100%' }}
                    minRows={3}
                  />
                )}
              </Grid>
              {isSameOrg && index === lastRequiredIndex && (
                <Grid item sm={12} xs={12}>
                  <MyTypography variant="h5" component="h5" color="text.primary">
                    The following fields can be omitted since you are sending to a clinic within
                    your own organization.
                  </MyTypography>
                </Grid>
              )}
            </>
          );
        })}
    </Grid>
  );
}
