import { gql } from '@apollo/client';
import { ACTIVE_RECORD_EXPR } from '../../common/gql';
import {
  GET_SPECIALIZE_CLINIC_FIELDS,
  GET_SPECIALIZATION_FIELDS,
  GET_REFERRAL_NOTES_FIELDS,
  GET_REFERRAL_FIELDS_BY_UUID,
  GET_PROVIDER_FIELDS,
} from './Fragment';

const GET_SPECIALIZATIONS_QUERY = gql`
  ${GET_SPECIALIZATION_FIELDS}
  query getSpecializations {
    rows: specializations {
      ...SpecializationFields
    }
  }
`;

const GET_FAVORITE_CLINICS_QUERY = gql`
  ${GET_SPECIALIZE_CLINIC_FIELDS}
  query getFavoriteClinics($uuid: uuid!) {
    rows: clinics(where: { favorite_clinics: { user: { uuid: { _eq: $uuid } } } }) {
      ...ClinicReferralFields
    }
    aggr: clinics_aggregate {
      res: aggregate {
        count
      }
    }
  }
`;

const GET_PROVIDERS = gql`
  ${GET_PROVIDER_FIELDS}
  query GetProviders($keyword: String) {
    rows: providers(keyword: $keyword) {
      ...ProviderFields
    }
  }
`;

const GET_PROVIDERS_MOST_RECENT = gql`
  ${GET_PROVIDER_FIELDS}
  query GetProvidersMostRecent {
    rows: providers_most_recent {
      ...ProviderFields
    }
  }
`;

const GET_PROVIDERS_FAVORITE = gql`
  ${GET_PROVIDER_FIELDS}
  query GetProvidersFavorite {
    rows: providers_favorite {
      ...ProviderFields
    }
  }
`;

const GET_REFERRAL_BY_UUID = gql`
  ${GET_REFERRAL_FIELDS_BY_UUID}
  query getReferralById($uuid: String!) {
    referral(uuid: $uuid) {
      ...ReferralFields
    }
  }
`;

const GET_REFERRAL_STATUS_BY_UUID = gql`
  query getReferralById($uuid: String!) {
    referral(uuid: $uuid) {
      status
      extra_info
      referrer {
        name
        clinic_users {
          user {
            uuid
          }
        }
      }
    }
  }
`;

const GET_REFERRAL_NOTES_BY_UUID = gql`
  ${GET_REFERRAL_NOTES_FIELDS}
  subscription getReferralNotes($uuid: uuid!, $userUUID: uuid!) {
    rows: referral_notes(
      order_by: { created_at: desc }
      where: {
        referral: { uuid: { _eq: $uuid } }
        _or: [
          {
            user: {
              clinic_users: { clinic: { clinic_users: { user: { uuid: { _eq: $userUUID } } } } }
            }
          }
          { messaging_group: { name: { _neq: "internal" } } }
        ]
      }
    ) {
      ...ReferralNotesFields
    }
  }
`;

const GET_DIAGNOSIS_QUERY = gql`
    query GetDiagnosisOptions($limit: Int!) {
        rows:diagnosis(where: ${ACTIVE_RECORD_EXPR}, order_by: {description: asc}, distinct_on:description, limit: $limit) {
            name:description
        }
    }
`;

const LOAD_PATIENT_BY_SSN = gql`
  query PatientBySsn($ssn: String!) {
    patient_by_ssn(ssn: $ssn) {
      firstname
      lastname
      phone
      birthdate
      address {
        line1
        city
        state
        zip
      }
      ssn
      mrn
      insurance_provider
      policy_number
    }
  }
`;

const REFERRAL_ANSWERS_QUERY = gql`
  query ReferralAnswers($uuid: String!) {
    answers: referral_answers(referral_uuid: $uuid) {
      name
      question
      answer
    }
  }
`;

export {
  GET_SPECIALIZATIONS_QUERY,
  GET_FAVORITE_CLINICS_QUERY,
  GET_PROVIDERS,
  GET_REFERRAL_BY_UUID,
  GET_REFERRAL_NOTES_BY_UUID,
  GET_DIAGNOSIS_QUERY,
  GET_REFERRAL_STATUS_BY_UUID,
  LOAD_PATIENT_BY_SSN,
  REFERRAL_ANSWERS_QUERY,
  GET_PROVIDERS_FAVORITE,
  GET_PROVIDERS_MOST_RECENT
};
