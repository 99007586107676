import CONFIG from "../../../config";

export const DEFAULT_ORIGINAL_FIELDS = [
  'diagnosis',
  'comment',
  'firstname',
  'lastname',
  'phone',
  'ssn',
  'mrn',
  'birthdate',
  'address_line1',
  'address_city',
  'address_state',
  'address_zip',
  'insurance_provider',
  'policy_number',
];

export const geocoderApiOptions = {
  country: 'us',
  language: 'en',
};

export const MAPBOX_ACCESS_TOKEN = CONFIG.MAPBOX_ACCESS_TOKEN;

export const FIELD_TYPES = [
  {
    value: 'text',
    label: 'Text Field',
  },
  {
    value: 'textarea',
    label: 'Text Area',
  },
  {
    value: 'numeric',
    label: 'Numeric Field',
  },
  {
    value: 'single_choice',
    label: 'Radio Button',
  },
  {
    value: 'multiple_choice',
    label: 'Checkbox',
  },
  {
    value: 'date',
    label: 'Date Field',
  },
  {
    value: 'phone',
    label: 'Phone Field',
  },
  {
    value: 'address',
    label: 'Address Field',
  },
];

export const DEFAULT_REFERRAL_FORM_FIELD = [
  {
    field: 'ssn',
    label: 'SSN',
    size: 12,
    required: true,
    hidden: false,
    kind: 'text',
    choices: [],
  },
  {
    field: 'mrn',
    label: 'MRN',
    size: 12,
    required: true,
    hidden: false,
    kind: 'text',
    choices: [],
  },
  {
    field: 'firstname',
    label: 'First Name',
    size: 6,
    required: true,
    hidden: false,
    kind: 'text',
    choices: [],
  },
  {
    field: 'lastname',
    label: 'Last Name',
    size: 6,
    required: true,
    hidden: false,
    kind: 'text',
    choices: [],
  },
  {
    field: 'birthdate',
    label: 'Birthdate',
    size: 6,
    required: true,
    hidden: false,
    kind: 'date',
    choices: [],
  },
  {
    field: 'phone',
    label: 'Phone',
    size: 6,
    required: true,
    hidden: false,
    kind: 'phone',
    choices: [],
  },
  {
    field: 'address_line1',
    label: 'Address Line',
    size: 6,
    required: true,
    hidden: false,
    kind: 'address',
    choices: [],
  },
  {
    field: 'address_city',
    label: 'City',
    size: 6,
    required: true,
    hidden: false,
    kind: 'address',
    choices: [],
  },
  {
    field: 'address_state',
    label: 'State',
    size: 6,
    required: true,
    hidden: false,
    kind: 'address',
    choices: [],
  },
  {
    field: 'address_zip',
    label: 'Zip',
    size: 6,
    required: true,
    hidden: false,
    kind: 'address',
    choices: [],
  },
  {
    field: 'insurance_provider',
    label: 'Insurance Provider',
    size: 6,
    required: true,
    hidden: false,
    kind: 'text',
    choices: [],
  },
  {
    field: 'policy_number',
    label: 'Policy Number',
    size: 6,
    required: true,
    hidden: false,
    kind: 'text',
    choices: [],
  },
  {
    field: 'diagnosis',
    label: 'Diagnosis',
    size: 12,
    required: true,
    hidden: false,
    kind: 'text',
    choices: [],
  },
  {
    field: 'comment',
    label: 'Comment',
    size: 12,
    required: false,
    hidden: false,
    kind: 'textarea',
    choices: [],
  },
];

export const DEFAULT_INTERNAL_REFERRAL_FORM_FIELD = [
  {
    field: 'ssn',
    label: 'SSN',
    size: 12,
    required: true,
    hidden: false,
    kind: 'text',
    choices: [],
  },
  {
    field: 'mrn',
    label: 'MRN',
    size: 12,
    required: true,
    hidden: false,
    kind: 'text',
    choices: [],
  },
  {
    field: 'firstname',
    label: 'First Name',
    size: 6,
    required: true,
    hidden: false,
    kind: 'text',
    choices: [],
  },
  {
    field: 'lastname',
    label: 'Last Name',
    size: 6,
    required: true,
    hidden: false,
    kind: 'text',
    choices: [],
  },
  {
    field: 'birthdate',
    label: 'Birthdate',
    size: 6,
    required: false,
    hidden: false,
    kind: 'date',
    choices: [],
  },
  {
    field: 'phone',
    label: 'Phone',
    size: 6,
    required: false,
    hidden: false,
    kind: 'phone',
    choices: [],
  },
  {
    field: 'address_line1',
    label: 'Address Line',
    size: 6,
    required: false,
    hidden: false,
    kind: 'address',
    choices: [],
  },
  {
    field: 'address_city',
    label: 'City',
    size: 6,
    required: false,
    hidden: false,
    kind: 'address',
    choices: [],
  },
  {
    field: 'address_state',
    label: 'State',
    size: 6,
    required: false,
    hidden: false,
    kind: 'address',
    choices: [],
  },
  {
    field: 'address_zip',
    label: 'Zip',
    size: 6,
    required: false,
    hidden: false,
    kind: 'address',
    choices: [],
  },
  {
    field: 'insurance_provider',
    label: 'Insurance Provider',
    size: 6,
    required: false,
    hidden: false,
    kind: 'text',
    choices: [],
  },
  {
    field: 'policy_number',
    label: 'Policy Number',
    size: 6,
    required: false,
    hidden: false,
    kind: 'text',
    choices: [],
  },
  {
    field: 'diagnosis',
    label: 'Diagnosis',
    size: 12,
    required: false,
    hidden: false,
    kind: 'text',
    choices: [],
  },
  {
    field: 'comment',
    label: 'Comment',
    size: 12,
    required: false,
    hidden: false,
    kind: 'textarea',
    choices: [],
  },
];
