// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { Authenticated } from '../auth';
import ViewExport from './ViewExport';

export default {
  path: 'export',
  name: 'Export',
  component: Authenticated,
  childRoutes: [
    {
      path: '/export',
      childRoutes: [
        { path: '', component: ViewExport },
      ],
    },
  ],
};
