import React, { useState } from "react";
import { Stack, Grid, Chip, IconButton, Box } from "@mui/material";
import { MyTypography } from "../../../common/components";
import { IconCopy, IconPencil } from "@tabler/icons";
import { capitalize, isEmpty, isObject } from "lodash";
import StringUtils from "../../../common/services/StringUtils";

function CopyButton({ value }) {
  const [isHover, setIsHover] = useState(false);

  if (!value) return null;

  const handleCopy = () => {
    const tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  };

  return (
    <IconButton
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={handleCopy}
      sx={{
        padding: 0,
        color: isHover ? "#FF494999" : "#001100",
      }}
    >
      <IconCopy style={{ height: 20, width: 20 }} />
    </IconButton>
  );
}

function renderValue(label, value, onEdit) {
  if (Array.isArray(value)) {
    return value.map((item, index) => (
      <Chip
        key={index}
        label={capitalize(isObject(item) ? item.name || item.document || "" : item)}
        size="small"
        color="secondary"
        variant="outlined"
      />
    ));
  }

  if (isObject(value)) {
    return (
      <>
        <MyTypography variant="subtitle1" sx={{ width: "250px" }}>
          {value.name}
        </MyTypography>
        <CopyButton value={value.name} />
      </>
    );
  }

  const isLongComment = label === "Comment" && !isEmpty(value) && value.length > 40;

  return (
    <>
      <MyTypography
        variant="subtitle1"
        sx={{ width: isLongComment ? "calc(100% - 100px)" : "250px" }}
      >
        {value || '---'}
      </MyTypography>
      <CopyButton value={value} />
    </>
  );
}

export function ReviewItem({ label, value, onEdit = null }) {
  const isFullWidth =
    label === "Permission" || (label === "Comment" && value?.length > 40);

  return (
    <Grid item md={isFullWidth ? 12 : 6} xs={12}>
      <Stack direction="column" sx={{ py: 1 }}>
        <MyTypography variant="caption" sx={{ fontWeight: 300 }}>
          {label}
        </MyTypography>
        <Stack direction="row" spacing={1} sx={{ py: 1 }}>
          {renderValue(label, value, onEdit)}
        </Stack>
      </Stack>
    </Grid>
  );
}

export default function ReviewCardGroup({ title, data, onEditGroup = null }) {
  return (
    <Grid item direction="column" container>
      <Grid item md={6} xs={12}>
        <Stack direction="row">
          <MyTypography variant="h4">{title}</MyTypography>
          {onEditGroup && typeof onEditGroup === "function" && (
            <>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton color="primary" onClick={onEditGroup}>
                <IconPencil />
              </IconButton>
            </>
          )}
        </Stack>
      </Grid>
      <Grid item container xs={12}>
        {!isEmpty(data) &&
          Object.entries(data).map((obj) => {
            const [key, value] = obj;
            return StringUtils.isJsonString(value) &&
              !isEmpty(JSON.parse(JSON.parse(value))) ? (
              Object.entries(JSON.parse(JSON.parse(value))).map((obj_2) => {
                const [key, value] = obj_2;
                return (
                  <ReviewItem
                    label={StringUtils.toTitleCase(key)}
                    value={value}
                    key={key}
                  />
                );
              })
            ) : (
              <ReviewItem
                label={StringUtils.toTitleCase(key)}
                value={value}
                key={key}
              />
            );
          })}
      </Grid>
    </Grid>
  );
}
