import React from 'react';
import {
    MenuItem,
} from '@mui/material';
import HookFormSelectOnly from '../../common/components/hook-form/HookFormSelectOnly';
const options = [
  { label: 'All', value: 'all' }, 
  { label: 'Yes', value: 'yes' }, 
  { label: 'No, Patient Declined', value: 'Patient Declined' }, 
  { label: 'No, No Show', value: 'No Show' }, 
  { label: 'No, Other', value: 'Other' },
];

export default function AppointmentMaintainedOptions({prefix=null, control=null, errors=null, onChange=null}) {
    return (
        <HookFormSelectOnly
            id="view"
            type="select"
            select
            placeholder="Select View"
            label="Appointment Maintained"
            schema="view"
            defaultValue={'all'}
            prefix={prefix}
            control={control}
            errors={errors}
            fullWidth
            onChange={onChange}
            options={options}
        />
    );
}