import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ApiService from '../../common/services/ApiService';
import { checkAuth, useLogIn } from '../redux/logIn';
import { isEmpty, isObject } from 'lodash';
import SessionStorage from '../../common/services/SessionStorage';
import {
  TWO_FACTOR_EXPIRED_ERROR_CODE,
  TWO_FACTOR_UNVERIFIED_ERROR_CODE,
} from '../constants/TwoFactor';
import Swal from 'sweetalert2';

const USER_KEY = 'my-user';

export const getUser = () => {
  return SessionStorage.get(USER_KEY);
};

export const setUser = (userData) => {
  SessionStorage.remove(USER_KEY);
  return SessionStorage.set(USER_KEY, userData);
};

export const logoutWithoutForward = () => {
  SessionStorage.remove(USER_KEY);
  ApiService.logout();
};


export const logout = () => {
  SessionStorage.remove(USER_KEY);

  ApiService.logout().finally(() => {
    window.location.href = '/login';
  });
};

export const useAuth = ({ middleware, redirectIfAuthenticated } = {}) => {
  const history = useHistory();
  const { logIn, logInPending, logInError, dismissLogInError } = useLogIn();
  const currentUser = getUser();
  const [authenticated, setAuthenticated] = useState(!!currentUser);
  const [user, setUser] = useState(currentUser ? currentUser : {});
  const [loading, setLoading] = useState(false);

  const login = async data => {
    setLoading(true);

    logIn(data)
      .then(userData => {
        if (isObject(userData) && !isEmpty(userData)) {
          SessionStorage.set(USER_KEY, userData);
          setUser(userData);
          setAuthenticated(true);
        } else {
          handleLogout();
        }
      })
      .catch(error => {
        if (error && error.status) {
          switch (error.status) {
            case 409:
              history.replace('/account/activate');
              break;
            case 422:
              throw error;
            case 401:
            default:
              history.replace('/login');
          }
        }
      })
      .finally(() => setLoading(false));
  };

  const handleLogout = useCallback(() => {
    SessionStorage.remove(USER_KEY);
    setAuthenticated(false);

    ApiService.logout().finally(() => {
      history.replace('/login');
    });
  }, [history]);

  useEffect(() => {
    if (!isObject(user)) return;

    if (isEmpty(user)) {
      history.replace('/login');
      return;
    }

    checkAuth()
      .then(() => {
        if (middleware === 'guest' && redirectIfAuthenticated && user) {
          if (user.role !== 'Admin' && user.referral_permissions === null && redirectIfAuthenticated.includes('dashboard')) {
            Swal.fire({
              icon: 'error',
              text: "You have no referral permissions. Please contact the system administrator.",
              showConfirmButton: true,
            }).then(() => {
              handleLogout();
            });
          }else if (
            user.role !== 'Admin' && user.referral_permissions !== null && 
            (user.referral_permissions.filter(e => e.key === 'ACCEPT_REFERRAL' && e.value).length >
              0 ||
              user.referral_permissions.filter(e => e.key === 'SCHEDULE_REFERRAL' && e.value)
                .length > 0 ||
              user.referral_permissions.filter(e => e.key === 'SEND_REFERRAL' && e.value).length >
              0) &&
            redirectIfAuthenticated.includes('dashboard')
          ) {
            history.replace('/referrals/active');
          } else if (user.role !== 'Admin' && redirectIfAuthenticated.includes('dashboard')) {
            history.replace('/referrals/declined');
          } else if (
            user.role === 'Admin' &&
            (redirectIfAuthenticated.includes('referral'))
          ) {
            if (!redirectIfAuthenticated.includes('/manage/referrals') && !redirectIfAuthenticated.includes('/manage/referral-networks')) {
              history.replace('/manage/clinics');
            }
          } else {
            history.replace(redirectIfAuthenticated);
          }
        }
      })
      .catch(err => {
        if (
          err?.data?.code === TWO_FACTOR_EXPIRED_ERROR_CODE ||
          err?.data?.code === TWO_FACTOR_UNVERIFIED_ERROR_CODE
        ) {
          history.replace('/auth/2fa/verify');
        } else if (err?.status === 401 || err?.status === 405) {
          handleLogout();
        }
      });
  }, [user, handleLogout, history, redirectIfAuthenticated, middleware]);

  return {
    user,
    isLoadingUser: loading,
    menuItems: user && Array.isArray(user.menu_items) ? { items: user.menu_items } : { items: [] },
    login,
    logInPending,
    logInError,
    dismissLogInError,
    authenticated,
    logout: handleLogout,
    loggedInAt: user?.loggedInAt,
  };
};
