import {
  Alert,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  useMutation,
  useQuery,
  useSubscription,
  useLazyQuery,
} from '@apollo/client';
import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { isEmpty } from 'lodash';
import { GET_ACTIVE_SENT_REFERRALS } from '../gql';
import ActiveSentReferralsTable from '../tables/ActiveSentReferralsTable';
import { usePromoteReferral } from '../../referral/redux/promoteReferral';
import Swal from 'sweetalert2';
import Referral from '../../referral/models/Referral';
import clientGraphql from '../../../common/apollo-graphql';
import AuthContext from '../../auth/context/AuthContext';
import { DELETE_REFERRAL_MUTATION } from '../../referral/gql/Mutation';
import { REFERRAL_REFRESH_SUBSCRIPTION } from '../../referral/gql/Subscription';
import ReferralCompleteDialog from '../dialogs/ReferralCompleteDialog';
import DialogReferralNotes from '../dialogs/DialogReferralNotes';
import moment from 'moment';
import SetAppointmentReferralDialog from '../dialogs/SetAppointmentReferralDialog';

export default function ActiveSentReferrals({ history, uuid }) {
  const [openNotes, setOpenNotes] = useState(false);
  const [closeError, setCloseError] = useState(false);
  const [type, setType] = useState('Name');
  const [patientName, setPatientName] = useState('');
  const [filterFax, setFilterFax] = useState('all');
  const [birthdate, setBirthdate] = useState('');
  const [parsedData, setParsedData] = useState({});
  const [referralUuid, setReferralUuid] = useState('');
  const currentUser = useContext(AuthContext);
  const handleClose = () => setOpenNotes(false);
  const [shouldSubscribe, setShouldSubscribe] = useState(true);
  const referralsSubscribeRef = useRef([]);
  const [appointmentDate, setAppointmentDate] = useState('');
  const [scheduleAppointmentDate, setScheduleAppointmentDate] = useState('');
  const [openCompleteDialog, setOpenCompleteDialog] = useState(false);
  const [openAppointmentDialog, setOpenAppointmentDialog] = useState(false);

  const [delete_referral] = useMutation(DELETE_REFERRAL_MUTATION, {
    client: clientGraphql,
  });

  const gqlParams = {
    client: clientGraphql,
    variables: {
      patientName: patientName || null,
      birthdate: birthdate || null,
      uuid: uuid,
      isFax: filterFax === 'fax' ? true : filterFax === 'none' ? false : null,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    skip: !uuid || Object.keys(uuid).length === 0,
  };

  const [getReferralActiveSent] = useLazyQuery(GET_ACTIVE_SENT_REFERRALS, gqlParams);
  const { loading, error, data } = useQuery(GET_ACTIVE_SENT_REFERRALS, gqlParams);

  const { data: referrals } = useSubscription(REFERRAL_REFRESH_SUBSCRIPTION, {
    variables: {
      clinic_id: currentUser?.clinics?.[0]?.id || null,
    },
    skip: !shouldSubscribe,
  });

  const settingParsedData = useCallback((data) => {
    setParsedData({
      rows: data.rows.map((item) => ({
        ...item,
        user: currentUser,
        referral: new Referral(item),
        actions: {
          onViewNotes: handleOnViewNotes,
          onUpdate: handleOnUpdate,
          onViewReferral: handleOnViewReferral,
          onComplete: () => handleOnComplete(
            item.id, 
            item.status, 
            item?.appointment?.appointment_date 
              ? moment(item.appointment.appointment_date.replace('T', ' '))
                  .format('DD MMM YYYY - HH:mm:ss') 
              : ''
          ),
          onSetAppointment: handleSetAppointment,
          onDelete: handleOnDelete,
        },
      })),
      count: data.rows.length,
    });
  }, [currentUser, handleOnViewNotes, handleOnUpdate, handleOnViewReferral, handleOnComplete, handleSetAppointment, handleOnDelete]);

  const handleRefetch = useCallback(() => {
    const variables = {
      patientName: patientName || null,
      birthdate: birthdate || null,
      uuid: currentUser.uuid,
      isFax: filterFax === 'fax' ? true : filterFax === 'none' ? false : null,
    };

    getReferralActiveSent({ variables }).then((res) => {
      const { data } = res;
      if (!isEmpty(data) && !isEmpty(data.rows)) {
        settingParsedData(data);
      }
    });
  }, [
    getReferralActiveSent,
    currentUser,
    patientName,
    birthdate,
    filterFax,
    settingParsedData,
  ]);

  useEffect(() => {
    if (!isEmpty(referrals)) {
      if (referralsSubscribeRef.current.length === 0) {
        referralsSubscribeRef.current = referrals;
      } else {
        handleRefetch();
      }
    }
  }, [referrals, handleRefetch]);

  const handleFilterFaxChanged = useCallback(
    (e) => {
      setFilterFax(e.target.value);
    },
    []
  );

  const handleFilterChanged = useCallback(
    (e) => {
      if (type === 'Name') {
        setPatientName(e.target.value);
      } else {
        setBirthdate(e.target.value);
      }
    },
    [type]
  );

  const handleChangeDropdown = useCallback(
    (e) => {
      setType(e.target.value);
      setBirthdate('');
      setPatientName('');
    },
    []
  );

  const handleOnUpdate = useCallback(
    (rowId) => {
      history.push(`/referrals/${rowId}?edit=1`);
    },
    [history]
  );

  const handleOnDelete = useCallback(
    (rowId) => {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          delete_referral({ variables: { referralUuid: rowId } }).then(() => {
            handleRefetch();
          });
        }
      });
    },
    [delete_referral, handleRefetch]
  );

  const handleOnViewReferral = useCallback(
    (rowId) => {
      history.push(`/referrals/${rowId}/review`);
    },
    [history]
  );

  const handleOnViewNotes = useCallback(
    (rowId) => {
      setReferralUuid(rowId);
      setOpenNotes(true);
    },
    []
  );

  const { promoteReferral } = usePromoteReferral();

  const onPromoteReferral = useCallback(
    (id, status, data) => {
      promoteReferral(id, status, data).then(() => {
        let tab = data == 'Referral Completed' ? 'Declined' : 'Referral History'
        Swal.fire({
          icon: 'success',
          text: `This referral has been moved to your ${tab} tab`,
          showConfirmButton: true,
        }).then(() => {
          handleRefetch();
        });
      }).catch(() => {
        Swal.fire({
          icon: 'error',
          text: `Failed to complete referral!`,
          showConfirmButton: true,
        })
      });
    },
    [promoteReferral, handleRefetch]
  );

  const handleOnComplete = useCallback(
    (rowId, status, appointmentDate) => {
      setReferralUuid(rowId);
      setAppointmentDate(appointmentDate);
      if(status != 'declined' && status != 'rec_completed') {
        setOpenCompleteDialog(true);
      } else {
        onPromoteReferral(rowId, 'ref_completed', 'Referral Completed');
      }
    },
    [setReferralUuid, setOpenCompleteDialog, setAppointmentDate, onPromoteReferral]
  );

  const handleSetAppointment = useCallback(
    (rowId, appointment) => {
      setScheduleAppointmentDate(appointment?.appointment_date);
      setReferralUuid(rowId);
      setOpenAppointmentDialog(true);
    },
    [setReferralUuid, setOpenAppointmentDialog]
  );

  const handleCompleteClose = useCallback(
    () => {
      setOpenCompleteDialog(false);
    },
    [setOpenCompleteDialog]
  );

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.rows)) {
      settingParsedData(data);
    } else {
      setParsedData({});
    }
  }, [data, settingParsedData]);

  useEffect(() => {
    console.log("Appointment Date has changed to:", appointmentDate);
  }, [appointmentDate]);

  return (
    <>
      {error && !closeError ? (
        <Alert
          variant="filled"
          severity="error"
          onClose={() => setCloseError(true)}
        >
          We are sorry, but we are having difficulty with our system. We are unable to load data at
          the moment. Please reach out to us if you continue to experience this issue.
        </Alert>
      ) : null}
      <ActiveSentReferralsTable
        onFilterChanged={handleFilterChanged}
        onFilterFaxChanged={handleFilterFaxChanged}
        onChangeDropdown={handleChangeDropdown}
        loading={loading}
        data={
          !isEmpty(parsedData) && !loading
            ? { rows: parsedData.rows, count: parsedData.count }
            : { rows: [], count: 0 }
        }
      />
      <DialogReferralNotes
        referralUuid={referralUuid}
        openNotes={openNotes}
        handleClose={handleClose}
        isExternal={true}
      />
        
      <ReferralCompleteDialog 
        initialAppointmentDate={appointmentDate}
        initialOpen={openCompleteDialog}
        referralUuid={referralUuid}
        onPromoteReferral={onPromoteReferral}
        onClose={handleCompleteClose}
      />

      <SetAppointmentReferralDialog
        onOpen={openAppointmentDialog}
        onClose={() => {
          setOpenAppointmentDialog(false);
          handleRefetch();
        }}
        history={history}
        uuid={referralUuid}
        appointmentDate={scheduleAppointmentDate}
        reschedule={scheduleAppointmentDate ? true : false}
      />
    </>
  );
}
