import { Dialog } from '@mui/material';
import React from 'react';
import MyPaperComponent from '../../common/components/MyPaperComponent';
import SetAppointmentReferral from '../../referral/SetAppointmentReferral';

export default function SetAppointmentReferralDialog({ onOpen, onClose, history, uuid, reschedule, appointmentDate = null }) {
  return (
    <Dialog
      open={onOpen}
      onClose={onClose}
      PaperComponent={MyPaperComponent}
      aria-labelledby="draggable-dialog-title"
      style={{ minWidth: '800px' }}
    >
      <SetAppointmentReferral
        history={history}
        uuid={uuid}
        handleClose={onClose}
        reschedule={reschedule}
        appointmentDate={appointmentDate}
      />
    </Dialog>
  );
}
