import { gql } from '@apollo/client';

const REFERRAL_TABLE_FIELDS = gql`
  fragment ReferralTableFields on Referral {
    id
    updated_at
    status
    reason
    accepted_at
    sent_at
    is_fax
    mrn
    diagnosis {
      description
    }
    appointment {
      scheduled_by
      appointment_date
      appointment_maintained
      appointment_details {
        reason
        confirm_appointment_date
        confirm_results
      }
    }
    updated_by {
      fullname
    }
    patient{
      firstname
      lastname
      full_name
      details {
        birthdate
      }
    }
    receiver{
      id
      name
    }
    referrer{
      name
    }
    provider {
      id
      display_name
    }
  }
`;

const ADMIN_REFERRAL_TABLE_FIELDS = gql`
  fragment ReferralAdminFields on Referral {
    id
    updated_at
    sent_at
    is_fax
    status
    reason
    updated_by {
      fullname
    }
    receiver{
      id
      name
    }
    referrer{
      name
    }
    provider {
      id
      display_name
    }
    appointment {
      appointment_date
    }
  }
`;

export { REFERRAL_TABLE_FIELDS,ADMIN_REFERRAL_TABLE_FIELDS };
