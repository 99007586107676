import React, {useCallback, useMemo, useContext } from 'react';
import { Card, CardContent, CardHeader, Box } from '@mui/material';
import { IconFile } from '@tabler/icons';

import useParsedTableData from '../../common/hooks/useParsedTableData';
import { gridSpacing } from '../../../common/themes/constants';
import Swal from 'sweetalert2';
import ReferralContext from '../../referral/context/ReferralContext';
import ReferralsTable from './ReferralsTable';

export default function ManageReferralsTable({ history, data, loading, providers = [], handleFilterFaxChanged, handleFilterChanged, fromAppointmentAging = false }) {
  const { id: referralId, handleRefetch } = useContext(ReferralContext);

  const handleOnDelete = useCallback(
    rowId => {
      Swal.fire({
        html: 'You are about to archive this document.',
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: false,
        confirmButtonText: 'Archive',
        denyButtonText: 'Delete',
      }).then(result => {
        // delete document
        handleRefetch();
      });
    },
    [handleRefetch],
  );

  const handleOnEdit = useCallback(
    rowId => {
      history.push(`/manage/documents/${rowId}`);
    },
    [history],
  );

  const actions = useMemo(() => {
    return { onEdit: handleOnEdit, onDelete: handleOnDelete };
  }, [handleOnDelete, handleOnEdit]);

  const { parsedData } = useParsedTableData(data, actions);

  const title = fromAppointmentAging ? 'Scheduled Referrals Aged 7 Days Past their Appointment Date' : 'Referrals';
 
  return (
    <>
      <Card>
        <CardHeader avatar={<IconFile />} title={title} />
        <CardContent>
          <Box sx={{ m: -3 }} direction="column" spacing={gridSpacing}>
            <ReferralsTable
              onFilterFaxChanged={handleFilterFaxChanged}
              onFilterChanged={handleFilterChanged}
              loading={loading}
              data={parsedData}
              showToolbar={true}
              fromAppointmentAging={fromAppointmentAging}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
