import { gql } from '@apollo/client';
import { ADMIN_REFERRAL_TABLE_FIELDS, REFERRAL_TABLE_FIELDS } from './Fragment';

const GET_REFERRALS = gql`
  ${ADMIN_REFERRAL_TABLE_FIELDS}
  query getReferrals($patientName: String, $birthdate: String, $isFax: Boolean, $status: [String]) {
    rows: referrals(patient_name: $patientName, birthdate: $birthdate, is_fax: $isFax, status: $status) {
      ...ReferralAdminFields
    }
  }
`;

const GET_ACTIVE_SENT_REFERRALS = gql`
  ${REFERRAL_TABLE_FIELDS}
  query getReferralActiveSent($patientName: String, $birthdate: String, $uuid: String!, $isFax:Boolean) {
    rows: referral_active_sent(patient_name: $patientName, birthdate: $birthdate, user_uuid: $uuid, is_fax:$isFax) {
      ...ReferralTableFields
    }
  }
`;

const GET_ACTIVE_RECEIVED_REFERRALS = gql`
  ${REFERRAL_TABLE_FIELDS}
  query getReferralActiveReceived($patientName: String, $birthdate: String, $uuid: String!) {
    rows: referral_active_received(patient_name: $patientName, birthdate: $birthdate, user_uuid: $uuid) {
      ...ReferralTableFields
    }
  }
`;

const GET_ACTIVE_RECEIVED_UNDER_REVIEW_REFERRALS = gql`
  ${REFERRAL_TABLE_FIELDS}
  query getReferralUnderReviewReceived($uuid: String!) {
    rows: referral_under_review_received(user_uuid: $uuid) {
      ...ReferralTableFields
    }
  }
`;

const GET_DECLINED_SENT_REFERRALS = gql`
  ${REFERRAL_TABLE_FIELDS}
  query getReferralDeclinedSent($uuid: String!) {
    rows: referral_declined_sent(user_uuid: $uuid) {
      ...ReferralTableFields
    }
  }
`;

const GET_DECLINED_RECEIVED_REFERRALS = gql`
  ${REFERRAL_TABLE_FIELDS}
  query getReferralDeclinedReceived($uuid: String!) {
    rows: referral_declined_received(user_uuid: $uuid) {
      ...ReferralTableFields
    }
  }
`;

const GET_COMPLETED_SENT_REFERRALS = gql`
  ${REFERRAL_TABLE_FIELDS}
  query getReferralCompletedSent($patientName: String, $birthdate: String, $uuid: String!, $isFax:Boolean, $appointmentMaintained: String) {
    rows: referral_completed_sent(patient_name: $patientName, birthdate: $birthdate, user_uuid: $uuid, is_fax:$isFax, appointment_maintained: $appointmentMaintained) {
      ...ReferralTableFields
    }
  }
`;

const GET_COMPLETED_RECEIVED_REFERRALS = gql`
  ${REFERRAL_TABLE_FIELDS}
  query getReferralCompletedReceived($uuid: String!) {
    rows: referral_completed_received(user_uuid: $uuid) {
      ...ReferralTableFields
    }
  }
`;

const GET_ADMIN_REFERRAL_COUNTS = gql`
query GetAdminReferralCounts($month: String, $year: String, $clinicReferrerId: ID, $clinicReceiverId: ID, $referrerId: ID) {
  admin_referral_counts(
    month: $month, 
    year: $year, 
    clinic_referrer_id: $clinicReferrerId, 
    clinic_receiver_id: $clinicReceiverId, 
    referrer_id: $referrerId
  ) {
    month
    year
    count
  }
}
`;

const GET_FAX = gql`
query GetFax {
  faxes {
    id
    receiver{
      id
      name
    }
    referrer {
      id
      name
    }
    status
    pagesSent
    units
    costPerUnit
    submitTime
    completionTime
    attemptsMade
  }
}
`;

const GET_REFERRALS_FOR_EXPORT = gql`
  ${REFERRAL_TABLE_FIELDS}
  query GET_REFERRALS_FOR_EXPORT($statuses: [String!], $startDate: String, $endDate: String) {
    rows: referrals_for_export(statuses: $statuses, start_date: $startDate, end_date: $endDate) {
      ...ReferralTableFields
    }
  }
`;

export {
  GET_REFERRALS,
  GET_REFERRALS_FOR_EXPORT,
  GET_ACTIVE_SENT_REFERRALS,
  GET_ACTIVE_RECEIVED_REFERRALS,
  GET_ACTIVE_RECEIVED_UNDER_REVIEW_REFERRALS,
  GET_DECLINED_SENT_REFERRALS,
  GET_DECLINED_RECEIVED_REFERRALS,
  GET_COMPLETED_SENT_REFERRALS,
  GET_COMPLETED_RECEIVED_REFERRALS,
  GET_ADMIN_REFERRAL_COUNTS,
  GET_FAX
};
