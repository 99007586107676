import { Button, Stack } from '@mui/material';
import React from 'react';
import { MyDataGrid } from '../../common/components';
import DateTimeUtils from '../../common/services/DateTimeUtils';

const columns = [
  {
    field: 'patient',
    headerName: 'Patient Name',
    width: 180,
    valueGetter: params => {
      return params.row.referral.getFullName();
    },
  },
  {
    field: 'mrn',
    headerName: 'MRN',
    width: 150,
    valueGetter: params => {
      return params.row.referral.getMrn();
    },
  },
  {
    field: 'dob',
    headerName: 'Date of Birth',
    width: 150,
    valueGetter: params => {
      return params.row.referral.getPatientDob();
    },
  },
  {
    field: 'diagnosis',
    headerName: 'Diagnosis',
    width: 180,
    valueGetter: params => {
      return params.row.referral.getDiagnosis();
    },
  },
  {
    field: 'receiver',
    headerName: 'Clinic Receiver',
    width: 250,
    valueGetter: params => {
      const name = params.row.receiver.name;
      return name;
    },
  },
  {
    field: 'provider',
    headerName: 'Provider',
    width: 160,
    valueGetter: params => {
      return params.row.referral.getProviderName();
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 180,
    headerAlign: 'center',
    sortable: false,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: params => {
      return (
        <Stack direction="row" spacing={1}>
          <Button
            onClick={e => {
              e.stopPropagation();
              params.row.actions.onViewNotes(params.row.id);
            }}
          >
            View Log
          </Button>
          <Button
            onClick={e => {
              e.stopPropagation();
              params.row.actions.onView(params.row.id);
            }}
          >
            Review
          </Button>
        </Stack>
      );
    },
  },
  {
    field: 'appointment_maintained',
    headerName: 'Maintained Appointment',
    width: 200,
    valueGetter: params => {
      return params.row.referral.isMaintainedAppointment();
    },
    renderCell: (params) => (
      <div style={{
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
      }} title={params.value}>
        {params.value}
      </div>
    ),
  },
  {
    field: 'appointments',
    headerName: 'Appointment Date',
    width: 200,
    valueGetter: params => {
      return params.row.referral.getDateOrReason();
    },
  },
  {
    field: 'sent_at',
    headerName: 'Date Sent',
    width: 250,
    valueGetter: params => {
      return params.row.referral.sentFormattedDate();
    },
    sortComparator: DateTimeUtils.dateSortComparator
  },
  {
    field: 'updated_by',
    headerName: 'Last Updated By',
    width: 250,
    valueGetter: params => {
      return params.row.referral.getLastUpdatedByFullName();
    },
  },
  {
    field: 'updated_at',
    headerName: 'Last Updated At',
    width: 250,
    valueGetter: params => {
      return params.row.referral.formattedDate();
    },
    sortComparator: DateTimeUtils.dateSortComparator
  },
];

export default function CompletedSentReferralsTable({
  onFilterChanged,
  onAppointmentMaintainedFilterChanged,
  onSelectedRowsChanged,
  loading,
  data,
  onClick,
}) {

  return (
    <MyDataGrid
      onAppointmentMaintainedFilterChanged={onAppointmentMaintainedFilterChanged}
      onSelectedRowsChanged={onSelectedRowsChanged}
      loading={loading}
      data={data}
      columns={columns}
      onClick={onClick}
      showToolbar={true}
      minTableHeight={'calc(100vh - 192px)'}
      getRowId={row => {
        return row.id;
      }}
    />
  );
}
