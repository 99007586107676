import { useMutation, useQuery } from '@apollo/client';
import { Card, CardContent, Dialog, Divider, Grid, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { gridSpacing } from '../../common/themes/constants';
import AuthContext from '../auth/context/AuthContext';
import { MyButton, MyTypography } from '../common/components';
import MyPaperComponent from '../common/components/MyPaperComponent';
import { usePromoteReferral } from './redux/promoteReferral';
import ReferralDetails from './ReferralDetails';
import DeclineReferral from './DeclineReferral';
import { ENDORSE_NOTIFICATION_MUTATION } from './gql/Mutation';
import clientGraphql from '../../common/apollo-graphql';
import { GET_REFERRAL_STATUS_BY_UUID } from './gql/Query';
import UploadReferralDocuments from '../patient/UploadReferralDocuments';
import SetAppointmentReferral from './SetAppointmentReferral';
import ReviewReferralNotes from './ReviewReferralNotes';
import FileUtils from '../common/services/FileUtils';
import { useFaxSend } from './redux/faxSend';
import HelperUtils from '../common/services/HelperUtils';
import { useDeclineReferral } from './redux/declineReferral';
import RenameDocDialog from '../patient/RenameDocDialog';

export default function ReviewReferral({ history }) {
  let { id } = useParams();
  const { faxSend, faxSendPending } = useFaxSend();
  const [isFaxSending, setIsFaxSending] = useState(false);
  const currentUser = useContext(AuthContext);
  const [patientDetails, setPatientDetails] = useState({});
  const [parsedData, setParsedData] = useState({});
  const [isReferrer, setIsReferrer] = useState(true);
  const [isSameOrg, setIsSameOrg] = useState(false);
  const [isCalled, setIsCalled] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [parsedDocumentData, setParsedDocumentData] = useState({});
  const [uploadedFileObjects, setUploadedFileObjects] = useState({});
  const [newFileList, setNewFileList] = useState([]);
  const [renameDoc, setRenameDoc] = useState([]);
  const { promoteReferral, promoteReferralPending } = usePromoteReferral();

  const [isSaveMisc, setIsSaveMisc] = useState(false);
  const [openDeclineReasonDialog, setOpenDeclineReasonDialog] = useState(false);
  const handleOpenDeclineReason = useCallback(() => {
    setOpenDeclineReasonDialog(true);
  }, [setOpenDeclineReasonDialog]);

  const [openUploadDocuments, setOpenUploadDocuments] = useState(false);
  const handleOpenUploadDocuments = () => setOpenUploadDocuments(true);
  const [isUpdateStatus, setIsUpdateStatus] = useState(false);
  const handleCloseUploadDocuments = useCallback(() => {
    setOpenUploadDocuments(false);
    setRefresh(true);
  }, []);

  const [openAppointmentDialog, setOpenAppointmentDialog] = useState(false);
  const handleOpenAppointment = useCallback(() => {
    setOpenAppointmentDialog(true);
  }, [setOpenAppointmentDialog]);

  const [uploadedMiscFiles, setUploadedMiscFiles] = useState([]);
  const [openRenameFile, setOpenRenameFile] = useState(false);
  const handleRenameClose = useCallback(() => {
    setOpenRenameFile(false);
    setRefresh(true);
  }, []);

  const {
    declineReferral
  } = useDeclineReferral();

  const onClickDeclineReferral = useCallback(
    (reason, title, message) => {
      promoteReferral(id, 'under_review').then(data => {
        declineReferral(id, reason).then(data => {
          Swal.fire({
            icon: 'success',
            title: title,
            text: message,
            showConfirmButton: true,
          }).then(() => {
            refetch();
          });
        }).catch(error=>{
          Swal.fire({
            icon: 'error',
            title: "Failed to decline referral.",
            text: (!isEmpty(error.data.message) ? error.data.message : JSON.stringify(error)) + " Please try to reload the referral.",
            showConfirmButton: true,
          }).then(() => {
  
          });
        });
      });
      
    },
    [declineReferral, id, promoteReferral, refetch],
  );

  const { data: loadedData, refetch, loading, error } = useQuery(GET_REFERRAL_STATUS_BY_UUID, {
    variables: {
      uuid: id,
    },
    client: clientGraphql,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
  });
  const data = loading || error ? undefined : loadedData;

  const [
    endorse_notification,
    {
      data: endorseNotificationData,
      loading: endorseNotificationLoading,
      error: endorseNotificationError,
    },
  ] = useMutation(ENDORSE_NOTIFICATION_MUTATION, {
    client: clientGraphql,
  });

  const handleCloseAppointment = useCallback(() => {
    setOpenAppointmentDialog(false);
  }, [setOpenAppointmentDialog]);

  const handleSetParsedData = useCallback(
    data => {
      setIsReferrer(false);
      setParsedData(data);
      if (
        !isEmpty(currentUser.organization) &&
        !isEmpty(parsedData) &&
        !isEmpty(parsedData.receiver) &&
        !isEmpty(parsedData.receiver.organization) &&
        parsedData.receiver.organization.name.toLowerCase() ===
          currentUser.organization.toLowerCase()
      ) {
        setIsSameOrg(true);
      }
      if (
        !isEmpty(data.referral.referral.referrer.clinic_users) &&
        !isEmpty(currentUser.clinics) &&
        data.referral.referral.referrer.name.toUpperCase() ===
          currentUser.clinics[0].name.toUpperCase()
      ) {
        data.referral.referral.referrer.clinic_users.map(item => {
          if (!isEmpty(item.user) && item.user != null && item.user.uuid === currentUser.uuid) {
            setIsReferrer(true);
            return;
          }
        });
      }
    },
    [setIsReferrer, currentUser, setParsedData, parsedData],
  );

  const handlePromoteReferral = useCallback(
    (status, title, message) => {
      promoteReferral(id, status)
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: title,
            text: message,
            showConfirmButton: true,
          }).then(() => {
            if (
              (status === 'under_review' &&
                currentUser.referral_permissions.filter(e => e.key === 'ACCEPT_REFERRAL' && e.value)
                  .length > 0) ||
              (status === 'accepted' &&
                currentUser.referral_permissions.filter(
                  e => e.key === 'SCHEDULE_REFERRAL' && e.value,
                ).length > 0)
            ) {
              refetch();
            } else {
              history.push('/referrals/active', { value: 1 });
            }
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Cannot promote referral',
            text: !isEmpty(error.data.message) ? error.data.message : JSON.stringify(error),
            showConfirmButton: true,
          }).then(() => {
            refetch();
          });
        });
    },
    [history, id, promoteReferral, refetch, currentUser],
  );

  const onPromoteToAccepted = useCallback(
    e => {
      e.preventDefault();
      handlePromoteReferral(
        'accepted',
        'Referral Accepted.',
        'Referral is forwarded for appointment.',
      );
    },
    [handlePromoteReferral],
  );

  const onPromoteToCompleted = useCallback(
    e => {
      e.preventDefault();
      promoteReferral(id, 'ref_completed').then(data => {
        Swal.fire({
          icon: 'success',
          text: 'Referral Completed.',
          showConfirmButton: true,
        }).then(() => {
          history.push('/referrals/active', { value: 1 });
        });
      });
    },
    [promoteReferral, id, history],
  );

  const onResendFaxAll = useCallback(
    e => {
      if(e != null){
        e.preventDefault();
      }
      if(!isEmpty(parsedData) && !parsedData.is_fax){
        return;
      }
      setIsFaxSending(true);
      FileUtils.faxSend(patientDetails, uploadedFileObjects, parsedData, currentUser).then(res=>{
        var faxNumber = HelperUtils.getFaxNumber(parsedData);
        const formData = new FormData();
        formData.append('file', res);
        formData.append('recipient', '001'+ faxNumber.replace('-',''));
        formData.append('referral_uuid', id);
        faxSend(formData).then(resultData => {
          Swal.fire({
            icon: 'success',
            text: resultData.message,
            showConfirmButton: true,
          }).then(() => {
            setIsFaxSending(false);
          });
        }).catch(errorData => {
          console.log('faxSend error', errorData);
          Swal.fire({
            icon: 'error',
            text: errorData.data.error,
            showConfirmButton: true,
          }).then(() => {
            setIsFaxSending(false);
          });
        });
      }).catch(errorData => {
        Swal.fire({
          icon: 'error',
          text: !isEmpty(errorData) ? errorData:"Error generating the file to fax.",
          showConfirmButton: true,
        }).then(() => {
          setIsFaxSending(false);
        });
      });
    },
    [patientDetails, uploadedFileObjects, parsedData, faxSend, currentUser, id, setIsFaxSending],
  );

  const onResendFax = useCallback(
    e => {
      if(e != null){
        e.preventDefault();
      }
      if(!isEmpty(parsedData) && !parsedData.is_fax){
        return;
      }

      setIsFaxSending(true);
      FileUtils.faxNewFiles(patientDetails, newFileList, parsedData, currentUser).then(res=>{
        var faxNumber = HelperUtils.getFaxNumber(parsedData);
        const formData = new FormData();
        formData.append('file', res);
        formData.append('recipient', '001'+ faxNumber.replace('-',''));
        formData.append('referral_uuid', id);
        faxSend(formData).then(resultData => {
          Swal.fire({
            icon: 'success',
            text: resultData.message,
            showConfirmButton: true,
          }).then(() => {
            setIsFaxSending(false);
            setNewFileList([]);
          });
        }).catch(errorData => {
          console.log('faxSend error', errorData);
          Swal.fire({
            icon: 'error',
            text: errorData.data.error,
            showConfirmButton: true,
          }).then(() => {
            setIsFaxSending(true);
            
          });
        });
      }).catch(errorData => {
        Swal.fire({
          icon: 'error',
          text: !isEmpty(errorData) ? errorData:"Error generating the file to fax.",
          showConfirmButton: true,
        }).then(() => {
          setIsFaxSending(false);
        });
      });;
    },
    [patientDetails, newFileList, parsedData, faxSend, currentUser, setNewFileList, id],
  );

  const onUpdateStatus = useCallback(
    e => {
      if (parsedData.is_fax) {
        Swal.fire({
          icon: 'question',
          text: 'Do you want to change the status?',
          showConfirmButton: true,
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: parsedData.status === 'sent' ? 'Accepted' : 'Scheduled',
          denyButtonText: parsedData.status === 'sent' ? 'Declined' : 'Close',
          cancelButtonText: 'Cancel',
        }).then(result => {
          if (result.isConfirmed && parsedData.status === 'sent') {
            onPromoteToAccepted(e);
          } else if (result.isConfirmed && parsedData.status === 'accepted') {
            e.preventDefault();
            handleOpenAppointment();
          } else if (result.isDenied && parsedData.status === 'sent') {
            e.preventDefault();
            onClickDeclineReferral({ reason: 'declined by referrer through fax.' }, 'Referral Declined','');
          } else if (result.isDenied && parsedData.status !== 'sent') {
            onPromoteToCompleted(e);
          }
        });
      } else {
        Swal.fire({
          icon: 'question',
          text: 'Do you want to change the status?',
          showConfirmButton: true,
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Under Review',
          denyButtonText: 'Decline',
          cancelButtonText: 'Cancel',
          footer: 'You may "Decline" the referral or put it back to "Under Review"',
        }).then(result => {
          if (result.isConfirmed) {
            onPromoteToUnderReview(e);
          } else if (result.isDenied) {
            e.preventDefault();
            setIsUpdateStatus(false);
            handleOpenDeclineReason();
          }
        });
      }
    },
    [
      parsedData,
      handleOpenDeclineReason,
      handleOpenAppointment,
      onPromoteToAccepted,
      onPromoteToUnderReview,
      setIsUpdateStatus,
      onClickDeclineReferral,
      onPromoteToCompleted
    ],
  );

  const onPromoteToUnderReview = useCallback(
    e => {
      e.preventDefault();
      setIsUpdateStatus(false);
      handlePromoteReferral(
        'under_review',
        'Referral Under Review.',
        'Referral is moved back to under review.',
      );
    },
    [handlePromoteReferral, setIsUpdateStatus],
  );

  const endorseReferralToNurse = useCallback(
    e => {
      e.preventDefault();
      endorse_notification({
        client: clientGraphql,
        variables: { referralUuid: id },
      });
    },
    [endorse_notification, id],
  );

  const isSetAppointmentVisible = useCallback(() => {
    if (isUpdateStatus) return false;
  
    const { status } = data.referral;
    const canSchedule = currentUser.actions.can_schedule;
  
    return (canSchedule && status === 'accepted') || (isReferrer && ['accepted', 'sent', 'under_review'].includes(status));
  }, [data, currentUser, isUpdateStatus, isReferrer]);  

  const isAddAppointFaxVisible = useCallback(() => {
    return parsedData.is_fax && data.referral.status.includes('faxed_successfully');
  }, [data, parsedData]);

  const isSetReAppointmentVisible = useCallback(() => {
    return (
      currentUser.actions.can_schedule && data.referral.status === 'scheduled' && !isUpdateStatus
    );
  }, [data, currentUser, isUpdateStatus]);

  const isAcceptVisible = useCallback(() => {
    return currentUser.actions.can_accept && data.referral.status === 'under_review';
  }, [data, currentUser]);

  const isDeclineVisible = useCallback(() => {
    return (
      currentUser.actions.can_accept &&
      (data.referral.status === 'under_review' ||
        (data.referral.status === 'accepted' && isUpdateStatus))
    );
  }, [data, currentUser, isUpdateStatus]);

  const isUpdateStatusVisible = useCallback(() => {
    return currentUser.actions.can_accept && data.referral.status === 'accepted' && !isUpdateStatus;
  }, [data, currentUser, isUpdateStatus]);

  const isResendReferralVisible = useCallback(() => {
    return isReferrer && !isEmpty(parsedData) && parsedData.is_fax && 
    (data.referral.status === 'under_review' || data.referral.status === 'sent' || data.referral.status === 'accepted' || data.referral.status === 'scheduled');
  }, [data, parsedData, isReferrer]);

  const isUnderReviewVisible = useCallback(() => {
    return currentUser.actions.can_accept && data.referral.status === 'accepted' && isUpdateStatus;
  }, [data, currentUser, isUpdateStatus]);

  const isEndorseVisible = useCallback(() => {
    return (
      currentUser.actions.can_endorse &&
      (data.referral.status === 'under_review' || data.referral.status === 'sent')
    );
  }, [data, currentUser]);

  useEffect(() => {
    if (!isEmpty(endorseNotificationError)) {
      const errors = !isEmpty(endorseNotificationError.graphQLErrors)
        ? endorseNotificationError.graphQLErrors[0]
        : {};
      Swal.fire({
        icon: 'error',
        text: 'Error Message: ' + endorseNotificationError.message,
        showConfirmButton: true,
      });
    } else if (endorseNotificationData) {
      Swal.fire({
        icon: 'success',
        text: 'Successfully endorsed referral',
        showConfirmButton: true,
      }).then(() => {
        refetch();
      });
    }
  }, [endorseNotificationError, refetch, endorseNotificationData]);

  const autopromoteToUnderReview = useCallback(
    (status, rowId) => {
      setIsCalled(true);
      promoteReferral(rowId, status).then(response => {
        refetch();
      });
    },
    [promoteReferral, refetch, setIsCalled],
  );

  useEffect(() => {
    if (
      !isEmpty(data) &&
      !isCalled &&
      !loading &&
      !promoteReferralPending &&
      data.referral.status === 'sent' &&
      !isReferrer &&
      !isEmpty(parsedData)
    ) {
      autopromoteToUnderReview('under_review', id);
    }
  }, [
    data,
    id,
    promoteReferralPending,
    autopromoteToUnderReview,
    loading,
    isCalled,
    isReferrer,
    parsedData,
  ]);

  return (
    <>
      <Grid container spacing={gridSpacing}>
        {!isEmpty(id) && !isEmpty(data) && !isEmpty(data.referral) ? (
          <Grid item md={12} xs={12}>
            <ReferralDetails
              isReferrer={isReferrer}
              referralId={id}
              onSetParsedData={handleSetParsedData}
              referralStatus={data.referral.status === 'sent' ? null : data.referral.status}
              refresh={refresh}
              setRefresh={setRefresh}
              patientDetails={patientDetails}
              setPatientDetails={setPatientDetails}
              uploadedFileObjects={uploadedFileObjects}
              setCreateFileObjects={setUploadedFileObjects}
            />
          </Grid>
        ) : null}

        {isEmpty(data) && !isEmpty(error) ? (
          <Grid item md={12} xs={12}>
            <div>
              {error?.message} <br />
              This referral is not part of {currentUser.clinics[0].name}
            </div>
          </Grid>
        ) : null}
      </Grid>
      {!isEmpty(data) ? (
        <Card>
          <CardContent>
            <Grid container spacing={gridSpacing} style={{ marginTop: 1 }}>
              {!isEmpty(data) && !isReferrer ? (
                <Grid item md={12} xs={12}>
                  <Divider />
                </Grid>
              ) : null}
              {!isEmpty(data) ? (
                !isEmpty(data) && !isEmpty(parsedData) ? (
                  <Grid item md={12} xs={12}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                      {!isReferrer && isEndorseVisible() ? (
                        <MyButton
                          type="button"
                          color="primary"
                          variant="outlined"
                          disabled={faxSendPending || isFaxSending || promoteReferralPending}
                          loading={promoteReferralPending}
                          onClick={endorseReferralToNurse}
                          fullWidth={false}
                        >
                          Endorse
                        </MyButton>
                      ) : null}
                      {!isReferrer && isAcceptVisible() ? (
                        <MyButton
                          type="button"
                          color="success"
                          variant="outlined"
                          disabled={faxSendPending || isFaxSending || promoteReferralPending}
                          loading={promoteReferralPending}
                          onClick={onPromoteToAccepted}
                          fullWidth={false}
                        >
                          Accept
                        </MyButton>
                      ) : null}

                      {isResendReferralVisible()  ? (
                        <MyButton
                          type="button"
                          color="primary"
                          variant="outlined"
                          disabled={faxSendPending || isFaxSending || promoteReferralPending}
                          loading={faxSendPending || isFaxSending }
                          onClick={onResendFaxAll}
                          fullWidth={false}
                        >
                          Re-send Fax Referral
                        </MyButton>
                      ) : null}
                      {(!isReferrer && isUpdateStatusVisible()) ? (
                        <MyButton
                          type="button"
                          color="primary"
                          variant="outlined"
                          disabled={faxSendPending || isFaxSending || promoteReferralPending}
                          loading={ promoteReferralPending}
                          onClick={onUpdateStatus}
                          fullWidth={false}
                        >
                          Update Status
                        </MyButton>
                      ) : null}
                      {!isReferrer && isDeclineVisible() ? (
                        <MyButton
                          type="button"
                          color="error"
                          variant="outlined"
                          disabled={faxSendPending || isFaxSending || promoteReferralPending}
                          loading={promoteReferralPending}
                          onClick={e => {
                            e.preventDefault();
                            setIsUpdateStatus(false);
                            handleOpenDeclineReason();
                          }}
                          fullWidth={false}
                        >
                          Decline
                        </MyButton>
                      ) : null}
                      {!isReferrer && isUnderReviewVisible() ? (
                        <MyButton
                          type="button"
                          color="success"
                          variant="outlined"
                          disabled={faxSendPending || isFaxSending || promoteReferralPending}
                          loading={promoteReferralPending}
                          onClick={onPromoteToUnderReview}
                          fullWidth={false}
                        >
                          Under Review
                        </MyButton>
                      ) : null}
                      {(isSetAppointmentVisible()) || isAddAppointFaxVisible() ? (
                        <MyButton
                          type="button"
                          color="primary"
                          variant="outlined"
                          disabled={faxSendPending || isFaxSending || promoteReferralPending}
                          onClick={e => {
                            e.preventDefault();
                            handleOpenAppointment();
                          }}
                          fullWidth={false}
                        >
                          Add Appointment Date/Time
                        </MyButton>
                      ) : null}
                      {isSetReAppointmentVisible()  ? (
                        <MyButton
                          type="button"
                          color="primary"
                          variant="outlined"
                          disabled={faxSendPending || isFaxSending || promoteReferralPending}
                          onClick={e => {
                            e.preventDefault();
                            handleOpenAppointment();
                          }}
                          fullWidth={false}
                        >
                          Reschedule Appointment Date/Time
                        </MyButton>
                      ) : null}
                    </Stack>
                  </Grid>
                ) : null
              ) : null}
              {isReferrer &&
              !isEmpty(parsedData) &&
              !isEmpty(data) &&
              (data.referral.status === 'under_review' ||
                data.referral.status === 'sent' ||
                data.referral.status === 'accepted' ||
                data.referral.status === 'scheduled') ? (
                <Grid item md={12} xs={12}>
                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                    <MyButton
                      type="button"
                      color="success"
                      variant="outlined"
                      disabled={faxSendPending || isFaxSending || promoteReferralPending}
                      onClick={handleOpenUploadDocuments}
                      fullWidth={false}
                    >
                      {!isEmpty(parsedData) && parsedData.is_fax ? 'Upload New Document to Fax':'Upload Documents'}
                    </MyButton>{' '}
                  </Stack>
                </Grid>
              ) : null}
              <Grid item md={12} xs={12}>
                <Divider />
              </Grid>
              {!isEmpty(parsedData) && (
                <>
                  <Grid item md={12} xs={12}>
                    <MyTypography variant="h4">Referral Log</MyTypography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    {isReferrer ? (
                      <MyTypography variant="h6">
                        In this section you may exchange messages with the receiving clinic and view
                        the system log.
                      </MyTypography>
                    ) : (
                      <MyTypography variant="h6">
                        In this section you may exchange messages between clinics, create Internal
                        Notes for use internally at your clinic, and to view the log for the
                        referral.
                      </MyTypography>
                    )}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <ReviewReferralNotes
                      referralUUID={id}
                      isReferrer={isReferrer}
                      parsedData={parsedData}
                      patientDetails={patientDetails}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>{' '}
        </Card>
      ) : null}

      {!isEmpty(id) &&
        !isEmpty(data) &&
        (data.referral.status === 'under_review' || data.referral.status === 'accepted') && (
          <Dialog
            open={openDeclineReasonDialog}
            onClose={() => setOpenDeclineReasonDialog(false)}
            PaperComponent={MyPaperComponent}
            aria-labelledby="draggable-dialog-title"
            style={{ minWidth: '800px' }}
          >
            <DeclineReferral
              history={history}
              uuid={id}
              setOpenDialog={setOpenDeclineReasonDialog}
              parsedData={parsedData}
            />
          </Dialog>
        )}

      <UploadReferralDocuments
        open={openUploadDocuments}
        PaperComponent={MyPaperComponent}
        history={history}
        uuid={id}
        setOpenDialog={handleCloseUploadDocuments}
        setOpenRenameFile={setOpenRenameFile}
        parsedDocumentData={parsedDocumentData}
        setParsedDocumentData={setParsedDocumentData}
        setUploadedMiscFiles={setUploadedMiscFiles}
        onResendFax={onResendFax}
        isFax = {!isEmpty(parsedData) && parsedData.is_fax}
        refresh={refresh}
        setRefresh={setRefresh}
        currentUser={currentUser}
        renameDoc={renameDoc}
        setRenameDoc={setRenameDoc}
        isSaveMisc={isSaveMisc}
        setIsSaveMisc={setIsSaveMisc}
        newFileList={newFileList}
        setNewFileList={setNewFileList}
      />

      <RenameDocDialog
        handleClose={handleRenameClose}
        openRenameFile={openRenameFile}
        renameDoc={renameDoc}
        setRenameDoc={setRenameDoc}
        setIsSaveMisc={setIsSaveMisc}
      />

      {!isEmpty(id) && !isEmpty(data) && ['accepted', 'sent', 'under_review', 'faxed_successfully'].includes(data.referral.status) && (
        <Dialog
          open={openAppointmentDialog}
          onClose={() => setOpenAppointmentDialog(false)}
          PaperComponent={MyPaperComponent}
          aria-labelledby="draggable-dialog-title"
          style={{ minWidth: '800px' }}
        >
          <SetAppointmentReferral
            history={history}
            uuid={id}
            handleClose={() => handleCloseAppointment()}
          />
        </Dialog>
      )}

      {!isEmpty(id) && !isEmpty(data) && (data.referral.status === 'scheduled' || data.referral.status === 'faxed_successfully') && (
        <Dialog
          open={openAppointmentDialog}
          onClose={() => setOpenAppointmentDialog(false)}
          PaperComponent={MyPaperComponent}
          aria-labelledby="draggable-dialog-title"
          style={{ minWidth: '800px' }}
        >
          <SetAppointmentReferral
            history={history}
            uuid={id}
            handleClose={() => handleCloseAppointment()}
            reschedule={true}
          />
        </Dialog>
      )}
    </>
  );
}
