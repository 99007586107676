import React, { useState, useContext } from 'react';
import { 
  Box, TextField, FormControlLabel, Checkbox, 
  Button, CircularProgress, Typography, 
  Link,
  Stack
} from '@mui/material';
import { useQuery } from '@apollo/client';
import AuthContext from '../auth/context/AuthContext';
import dayjs from 'dayjs';
import { GET_REFERRALS_FOR_EXPORT } from '../referrals/gql';
import clientGraphql from '../../common/apollo-graphql';
import Referral from '../referral/models/Referral';
import Papa from "papaparse";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  DataGrid,
  GridToolbarContainer,
  gridVisibleSortedRowIdsSelector,
  useGridApiContext,
} from '@mui/x-data-grid';
import DateTimeUtils from '../common/services/DateTimeUtils';
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { MyTypography } from '../common/components';
import { IconDeviceLandlinePhone } from '@tabler/icons';
dayjs.extend(quarterOfYear);

export default function ViewExport() {
  const currentUser = useContext(AuthContext);
  const [startDate, setStartDate] = useState(dayjs().startOf('quarter').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs().add(1, 'day').format('YYYY-MM-DD'));
  const [statuses, setStatuses] = useState(['active', 'declined', 'completed']);
  const [pageSize, setPageSize] = useState(25);

  const { loading, data } = useQuery(GET_REFERRALS_FOR_EXPORT, {
    client: clientGraphql,
    variables: { statuses, startDate, endDate },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !currentUser,
  });

  const referrals = data?.rows?.map(item => ({
    ...item,
    referral: new Referral(item),
  }));

  const columns = [
    {
      field: 'uuid',
      headerName: 'Referral #',
      width: 300,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.id,
      renderCell: params => (
        <Link href={`/referrals/${params.row.id}/review`} target="_blank" rel="noopener noreferrer">
          {params.row.id}
        </Link>
      ),
    },
    {
      field: 'status',
      headerName: 'Referral Status',
      width: 150,
      disableColumnMenu: true,
      valueGetter: params => params.row.referral.getReferrerStatus(),
    },
    {
      field: 'patient',
      headerName: 'Patient Name',
      width: 180,
      disableColumnMenu: true,
      valueGetter: params => params.row.referral.getFullName(),
    },
    {
      field: 'mrn',
      headerName: 'MRN',
      width: 150,
      disableColumnMenu: true,
      valueGetter: params => params.row.referral.getMrn(),
    },
    {
      field: 'dob',
      headerName: 'Date of Birth',
      width: 150,
      disableColumnMenu: true,
      valueGetter: params => params.row.referral.getPatientDob(),
    },
    {
      field: 'diagnosis',
      headerName: 'Diagnosis',
      width: 180,
      disableColumnMenu: true,
      valueGetter: params => params.row.referral.getDiagnosis(),
    },
    {
      field: 'date_sent',
      headerName: 'Date Sent',
      width: 180,
      disableColumnMenu: true,
      valueGetter: params => params.row.referral.sentFormattedDate(),
      sortComparator: DateTimeUtils.dateSortComparator
    },
    {
      field: 'receiver',
      headerName: 'Clinic Receiver',
      width: 250,
      disableColumnMenu: true,
      valueGetter: params => params.row.receiver.name,
      renderCell: params => {
        return (
          <Stack direction="row" spacing={1}>
            {params.row.is_fax && <IconDeviceLandlinePhone />}
            <MyTypography variant="subtitle1">{params.row.receiver.name}</MyTypography>
          </Stack>
        );
      },
    },
    {
      field: 'provider',
      headerName: 'Provider',
      width: 250,
      disableColumnMenu: true,
      valueGetter: params => params.row.provider.display_name
    },
    {
      field: 'appointment_maintained',
      headerName: 'Maintained Appointment',
      width: 200,
      disableColumnMenu: true,
      valueGetter: params => {
        return params.row.referral.isMaintainedAppointment();
      },
      renderCell: (params) => (
        <div style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
        }} title={params.value}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'appointment_date',
      headerName: 'Appointment Date/Decline Reason',
      width: 250,
      disableColumnMenu: true,
      valueGetter: params => {
        return params.row.referral.getDateOrReason();
      },
      sortComparator: DateTimeUtils.dateSortComparator,
    },
  ];

  // Handle checkbox change
  const handleStatusChange = (event) => {
    const { value, checked } = event.target;
    setStatuses((prev) =>
      checked ? [...prev, value] : prev.filter((status) => status !== value)
    );
  };

  // Custom toolbar with Export Button
  function CustomToolbar() {
    const apiRef = useGridApiContext();

    const handleExport = () => {
      // Get raw data from DataGrid
      const csvData = apiRef.current.getDataAsCsv();
      const csv = Papa.unparse(Papa.parse(csvData, { delimiter: "," }).data, {
        quotes: true, // ✅ Ensures ALL values are wrapped in double quotes
        delimiter: ",",
        header: true,
      });
    
      // Create a Blob and trigger a download
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Referrals_${dayjs().format("YYYY-MM-DD")}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return (
      <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" sx={{ ml: 2 }}></Typography>
        <Button variant="contained" onClick={() => handleExport({ getRowsToExport: gridVisibleSortedRowIdsSelector(apiRef) })} sx={{ m: 2 }}>Export File</Button>
      </GridToolbarContainer>
    );
  }

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography variant="h3" sx={{ mb: 2, fontWeight: 'bold' }}>Export Sent Referrals</Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Sent Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue.format('YYYY-MM-DD'))}
            renderInput={(params) => <TextField {...params} sx={{ width: '200px', mr: 4 }} />}
          />

          <DatePicker
            label="Sent End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue.format('YYYY-MM-DD'))}
            renderInput={(params) => <TextField {...params} sx={{ width: '200px', mr: 4 }} />}
          />
        </LocalizationProvider>

        <Box sx={{ position: 'relative' }}>
          <Box sx={{
            position: 'absolute', top: '-10px', left: '12px',
            background: '#fafafa', fontSize: '11px', color: '#9e9e9e', padding: '0 4px',
          }}>
            Status
          </Box>
          <Box sx={{ display: 'flex', border: '1px solid #ccc', borderRadius: '4px', padding: '3px 16px 6px' }}>
            {['active', 'declined', 'completed'].map((status) => (
              <FormControlLabel key={status}
              control={<Checkbox checked={statuses.includes(status)} onChange={handleStatusChange} value={status} />}
                label={status.charAt(0).toUpperCase() + status.slice(1)}
              />
            ))}
          </Box>
        </Box>
      </Box>

      {loading ? <CircularProgress /> : referrals.length > 0 ? (
        <Box sx={{ mt: 2, height: '70vh', width: '100%' }}>
          <DataGrid
            rows={referrals}
            rowCount={referrals.length}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[15, 25, 50]}
            isRowSelectable={() => false}
            showCellRightBorder
            showCellLeftBorder
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
          />
        </Box>
      ) : (
        <Typography>No referrals found.</Typography>
      )}
    </Box>
  );
}
