import { Button, IconButton, Stack } from '@mui/material';
import React, { useContext } from 'react';
import { MyDataGrid, MyTypography } from '../../common/components';
import DateTimeUtils from '../../common/services/DateTimeUtils';
import { common_cols } from './ActiveSentReferralsTable';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AuthContext from '../../auth/context/AuthContext';

export default function ActiveReceivedReferralsTable({
  onFilterChanged,
  onChangeDropdown,
  onSelectedRowsChanged,
  loading,
  data,
  onClick,
  onViewNotes,
}) {

  const currentUser = useContext(AuthContext);

  const updated_cols = common_cols.map(col =>
    col.field === 'mrn' ? { ...col, headerName: 'Sending Clinic MRN' } : col
  );

  const columns = [
    ...updated_cols,
    {
      field: 'referrer',
      headerName: 'Referral Source',
      width: 250,
      valueGetter: params => {
        return params.row.referral.getReferrerClinic();
      },
      renderCell: params => {
        return (
          <Stack direction="column" spacing={1}>
            <MyTypography variant="h5" component="h5" color="text.primary" style={{'margin': '0'}}>
              {params.row.referral.getReferrerClinic()}
            </MyTypography>
            {params.row.referral.getReferrerClinic().includes('★') ? (<MyTypography variant="h6" component="h6" color="text.primary" style={{'fontStyle': 'italic', 'margin': '0'}}>
              Submitted via Public Form
            </MyTypography>):null}
            
          </Stack>
        );
      },
    },
    {
      field: 'provider',
      headerName: 'Provider',
      width: 160,
      valueGetter: params => {
        return params.row.referral.getProviderName();
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      valueGetter: params => {
        return params.row.referral.getReceiverStatus();
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 252,
      headerAlign: 'center',
      sortable: false,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: params => {
        return (
          <Stack direction="row" spacing={1}>
            <Button
              color="secondary"
              onClick={e => {
                e.stopPropagation();
                params.row.actions.onViewNotes(params.row.id);
              }}
            >
              View Log
            </Button>
            <Button
              color="secondary"
              onClick={e => {
                e.stopPropagation();
                params.row.actions.onReview(params.row.id, params.row);
              }}
            >
              Review
            </Button>
            {(params.row.status === 'declined' ||
            params.row.status === 'scheduled' ||
            params.row.status === 'ref_completed') ? (
              <Button
                color="secondary"
                onClick={e => {
                  e.stopPropagation();
                  params.row.actions.onComplete(params.row.id);
                }}
              >
                Complete
              </Button>
            ) : null}
          </Stack>
        );
      },
    },
    {
      field: 'appointments',
      headerName: 'Appointment Date or Decline Reason',
      width: 300,
      valueGetter: params => {
        return params.row.referral.getDateOrReason();
      },
      renderCell: (params) => {
        const value = params.row.referral.getDateOrReason();
        const referral = params.row.referral?.referral;

        if (currentUser.actions.can_schedule && !referral.reason) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{value}</span>
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  params.row.actions.onSetAppointment(params.row.id, referral.appointment);
                }}
                size="small"
                style={{ marginLeft: 8 }}
              >
                {value ? <EditIcon fontSize="small" /> : <AddIcon fontSize="small" />}
              </IconButton>
            </div>
          );
        } else if(value){
          return <span>{value}</span>;
        }
      },
    },
    {
      field: 'sent_at',
      headerName: 'Date Received',
      width: 250,
      valueGetter: params => {
        return params.row.referral.receivedFormattedDate();
      },
      sortComparator: DateTimeUtils.dateSortComparator
    },
    {
      field: 'updated_by',
      headerName: 'Last Updated By',
      width: 250,
      valueGetter: params => {
        return params.row.referral.getLastUpdatedByFullName();
      },
    },
    {
      field: 'updated_at',
      headerName: 'Last Updated At',
      width: 250,
      valueGetter: params => {
        return params.row.referral.formattedDate();
      },
      sortComparator: DateTimeUtils.dateSortComparator
    },
  ];

  return (
    <MyDataGrid
      onFilterChanged={onFilterChanged}
      onChangeDropdown={onChangeDropdown}
      onSelectedRowsChanged={onSelectedRowsChanged}
      loading={loading}
      data={data}
      columns={columns}
      onClick={onClick}
      onViewNotes={onViewNotes}
      showToolbar={true}
      selections={['By Name', 'By Birthdate']}
      minTableHeight={'calc(100vh - 192px)'}
      getRowId={row => {
        return row.id;
      }}
    />
  );
}
